
import { useCompanyFetch } from "../../layout/_index.layout";
import { ProductsPageHooksStates } from "../../pages/_index.pages";
import { calculatePercentageDiscount } from "../../utils/_index.utils";
import { Spinner } from "../_index.components";
import { FiltersHooks } from "./_index.filters";

export type TFiltersProps = ProductsPageHooksStates & {};

export default function Filters({ ...props }: TFiltersProps) {
  const uniqueDiscounts = new Set();

  const states = FiltersHooks({ ...props } as TFiltersProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <section className="w-auto p-2 bg-none gap-10 flex flex-col">
        <div className="w-full space-y-3">
          <h2 className="text-xl">
            <strong>
              Categorias
            </strong>
          </h2>
          <ul className="w-full space-y-2 flex flex-col">
            {
              states.categoryList?.map((item, i) => (
                <li className="inline-flex gap-2 cursor-pointer" key={i}>
                  <span className="whitespace-nowrap">
                    {item.name}
                  </span>
                  <span style={{
                    color: companyFetched?.ThirdColorLight
                  }}
                  >
                    ({states.productsList.filter(product => product.category === item._id).length})
                  </span>
                </li>
              ))
            }
          </ul>
        </div>

        <div className="w-full space-y-3">
          <h2 className="text-xl">
            <strong>
              Preços
            </strong>
          </h2>
          <ul className="w-full space-y-2 flex flex-col">
            {
              states.pricesList?.map((item, i) => (
                <li className="inline-flex gap-2 cursor-pointer" key={i}>
                  <span className="whitespace-nowrap">
                    {item.price}
                  </span>
                  <span style={{
                    color: companyFetched?.ThirdColorLight
                  }}
                  >
                    ({item.amount})
                  </span>
                </li>
              ))
            }
          </ul>
        </div>

        <div className="w-full space-y-3">
          <h2 className="text-xl">
            <strong>
              Descontos
            </strong>
          </h2>
          <ul className="w-full space-y-2">
            {
              states.productsList
                .map((item, i) => ({
                  ...item,
                  discountPercentage: calculatePercentageDiscount(item.realPrice, item.promotionPrice)
                }))
                .sort((b, a) => b.discountPercentage - a.discountPercentage)
                .filter((item) => {
                  if (!uniqueDiscounts.has(item.discountPercentage)) {
                    uniqueDiscounts.add(item.discountPercentage);
                    return true;
                  }
                  return false;
                })
                .map((item, i) => (
                  <li key={i}>
                    <a className="cursor-pointer">
                      <span className="space-x-1 flex items-start">
                        <span className="whitespace-nowrap">Mais de {item.discountPercentage}%</span>
                        <span className="whitespace-nowrap">OFF</span>
                      </span>
                    </a>
                  </li>
                ))
            }
          </ul>

        </div>
        <div className="w-full space-y-3">
          <h2 className="text-xl">
            <strong>
              Custo de Frete
            </strong>
          </h2>
          <ul className="w-full space-y-2">
            <li>
              <a className="cursor-pointer">
                Grátis
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
};