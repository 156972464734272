import { Icons } from "../../../assets/icons/_index.icons";
import { Spinner, Image } from "../../../components/_index.components";
import { TCompanyFetched } from "../../../layout/_index.layout";
import { HomeBannerHooks, THomeBannerHooksStates } from "./_index.partials"

import GifSpinnerBanner from "../../../assets/img/spinnerBanner.gif";

export type THomeBannerProps = {
  companyFetched: TCompanyFetched;
  loading: boolean;
};

export default function HomeBanner({ ...props }: THomeBannerProps) {

  const states: THomeBannerHooksStates = HomeBannerHooks({ ...props } as THomeBannerProps);

  if (states.loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <div className="w-full h-[35vh] p-1 rounded-lg flex justify-center items-center"
        style={{
          background: states.companyFetched?.secondaryColorLight,
          boxShadow: "0px 4px 4px 0px rgba(185, 181, 181, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <div className="absolute bottom-2 left-0 right-0 flex justify-center">
          {
            states.imageBanner &&
            states.images?.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 bg-gray-300 rounded-full mx-1 ${index === states.activeIndex ? "bg-gray-500" : ""
                  }`}
              />
            ))
          }
        </div>
        {
          states.imageBanner
            ? <Image
              path={process.env.REACT_APP_BUCKET_URL + states.imageBanner?.imageThumbUrl}
              style="w-full h-full bg-cover rounded-lg"
            />
            : (
              <img
                className="w-auto h-auto rounded-lg"
                src={GifSpinnerBanner}
                alt="Banner"
              />
            )
        }
        <div className="absolute top-0 bottom-0 left-0 flex items-center">
          <button
            className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -ml-10 nd:-ml-5"
            onClick={states.goToPrevImage}
          >
            <Icons.previous size={50} />
          </button>
        </div>
        <div className="absolute top-0 bottom-0 right-0 flex items-center">
          <button
            className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -mr-10 nd:-mr-5"
            onClick={states.goToNextImage}
          >
            <Icons.next size={50} />
          </button>
        </div>
      </div>
    </>
  )
};