import { useEffect, useState } from "react";
import { DropdownHooks, TDropdownHooksStates } from "../../Dropdown/_index.dropdown";
import { MenuProdutos } from "../../_index.components";
import { TMenuProps } from "../Menu";
import { RoutesPath } from "../../../routes/AppRoutes.routes";
import { ICategory } from "../../../interfaces/ICategories";

type TMenuHooksProps = TMenuProps & {};
export type TMenuHooksStates =
  TMenuHooksProps &
  TDropdownHooksStates & {
    menuModules: any[];
    featuredModule: any | null;
    featuredId: string;
    featuredRoute: string;
    hoveredDropdown: boolean;
    setFeaturedId: (data: string) => void;
    setHoveredDropdown: (data: boolean) => void;
    setFeaturedRoute: (data: string) => void;
    setFeaturedModule: (data: any | null) => void;
  };

export const MenuHooks = ({ ...props }: TMenuHooksProps): TMenuHooksStates => {
  const { open, close, toggle } = DropdownHooks();

  const [featuredModule, setFeaturedModule] = useState<any | null>(null);
  const [featuredId, setFeaturedId] = useState<string>("");
  const [featuredRoute, setFeaturedRoute] = useState<string>("");

  const [hoveredDropdown, setHoveredDropdown] = useState<boolean>(false);

  const menuModules = [
    {
      name: "Inicio",
      _id: "header-inicio",
      onClick: null,
      onHover: null,
      route: RoutesPath.home,
    },
    {
      name: "Mais vendidos",
      _id: "header-mais-vendidos",
      onClick: null,
      onHover: null,
      route: RoutesPath.bestsellers,
    },
    {
      name: "Promoções",
      _id: "header-promocoes",
      onClick: null,
      onHover: null,
      route: RoutesPath.promotions,
    },
    {
      name: "Lançamentos",
      _id: "header-lancamentos",
      onClick: null,
      onHover: null,
      route: RoutesPath.launchs,
    },
    {
      name: "Todos os produtos",
      _id: "header-todos-produtos",
      onClick: null,
      onHover: MenuProdutos,
      route: RoutesPath.products,
    },
    {
      name: "Contato",
      _id: "header-contato",
      onClick: null,
      onHover: null,
      route: RoutesPath.contact,
    },
  ];

  return {
    ...props,
    open,
    featuredModule,
    featuredId,
    menuModules,
    featuredRoute,
    hoveredDropdown,
    setHoveredDropdown,
    setFeaturedRoute,
    close,
    toggle,
    setFeaturedId,
    setFeaturedModule,
  }
};