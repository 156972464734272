import NoDataImg from "../../assets/img/nodata-removebg-preview.png";
import { useCompanyFetch } from "../../layout/_index.layout";

export type TNoDataProps = {}

export default function NoData({ ...props }: TNoDataProps) {
  const { companyFetched } = useCompanyFetch();

  return (
    <>
      <section className="w-full h-full">
        <div className="w-full h-full items-center justify-center inline-flex gap-8">
          <img
            alt="nodata"
            src={NoDataImg}
          />
          <div className="flex flex-col gap-4 h-auto w-auto">
            <h2
              className="text-3xl font-bold"
              style={{
                color: companyFetched?.primaryColorDark
              }}
            >
              Ooops...
            </h2>
            <p
              className="text-lg filter brightness-200"
              style={{
                color: companyFetched?.primaryColorDark
              }}
            >
              Não encontramos dados a serem exibidos. Tente efetuar uma nova busca ou cadastrar dados para visualizar este conteúdo.
            </p>
          </div>
        </div>
      </section>
    </>
  )
};
