import { StructurePages } from "../_index.pages";

import ImgNoImageAvaible from "../../assets/img/noImage.png";

import { useCompanyFetch } from "../../layout/_index.layout";
import { Icons } from "../../assets/icons/_index.icons";

import {
  Image,
  Modal,
  ProductCard,
  Spinner
} from "../../components/_index.components";

import {
  ProductsDetailsPageHooks,
  TProductsDetailsPageHooksStates
} from "./Hooks/_index.hooks";

import {
  calculatePercentageDiscount,
  formatToCurrency
} from "../../utils/_index.utils";


export type TProductsDetailsPageProps = {};

export default function ProductsDetailsPage({ ...props }: TProductsDetailsPageProps) {

  const states: TProductsDetailsPageHooksStates = ProductsDetailsPageHooks({ ...props } as TProductsDetailsPageProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex flex-col gap-8">
              <div
                className="inline-flex w-full h-auto p-5 gap-12"
                style={{
                  background: companyFetched?.secondaryColorLight,
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  backdropFilter: "blur(5.5px)",
                }}
              >
                <div className="flex flex-col w-full h-auto gap-8">
                  <div className="w-full h-auto inline-flex">
                    <div className="w-auto h-auto flex flex-col gap-3 p-3">
                      {
                        states.productObj?.image?.map((item: any, i: number) => (
                          <div
                            className={
                              `h-[7vh] w-[7vh] cursor-pointer 
                              ${states.featuredImageIndex === i ? "border-2 bg-[#6087db] rounded-lg p-[3px]" : "border-0"}`
                            }
                            key={i}
                            onClick={() => states.setFeaturedImageIndex(i)}
                          >
                            <Image
                              path={process.env.REACT_APP_BUCKET_URL + item?.imageThumbUrl}
                              style="w-full h-full rounded-lg bg-cover brightness-75 hover:brightness-125"
                            />
                          </div>
                        ))
                      }
                    </div>
                    <div
                      className="w-[33vw] rounded-lg h-auto nd:min-h-[40vh] flex flex-col gap-2 p-2 cursor-pointer"
                      onClick={states.toggle}
                    >
                      {
                        states.featuredImage
                          ? <Image
                            path={process.env.REACT_APP_BUCKET_URL + states.featuredImage?.imageUrl}
                            onMouseEnter={states.handleMouseEnter}
                            onMouseLeave={states.handleMouseLeave}
                            onMouseMove={states.handleMouseMove}
                            style="w-full h-auto max-h-[80%] nd:h-[50%] rounded-lg bg-cover cursor-zoom-in"
                          />
                          : <Image path={ImgNoImageAvaible} />
                      }
                    </div>
                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Produtos relacionados</h1>
                    <section className="w-full h-auto">
                      <div>
                        <section className="w-full h-full nd:h-auto nd:overflow-hidden nd:gap-4 grid grid-cols-4 nd:grid-cols-2">
                          {
                            states.productList
                              ?.slice(states.currentPage * states.productsPerPage, (states.currentPage + 1) * states.productsPerPage)
                              ?.map((item, index) => (
                                <ProductCard
                                  item={item} key={index}
                                />
                              ))
                          }
                        </section>
                        <div className="absolute top-0 bottom-0 left-0 flex items-center">
                          <button
                            className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -ml-10 nd:-ml-5"
                            onClick={states.prevPage}
                          >
                            <Icons.previous size={30} />
                          </button>
                        </div>
                        <div className="absolute top-0 bottom-0 right-0 flex items-center">
                          <button
                            className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -mr-10 nd:-mr-5"
                            onClick={states.nextPage}
                          >
                            <Icons.next size={30} />
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Características do produto</h1>
                    <div className="inline-flex gap-16 w-full">
                      <div className="flex-grow">
                        <table className="h-auto w-full">
                          <tbody>
                            <tr className="bg-gray-100">
                              <th className="text-left p-3">Marca:</th>
                              <td className="text-center p-3">{states.productObj?.brand}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="flex-grow">
                        <table className="w-full">
                          <tbody>
                            <tr className="bg-gray-100">
                              <th className="text-left p-3">Unidade de Medida:</th>
                              <td className="text-center p-3">{states.productObj?.unitMeasurement}</td>
                            </tr>
                            <tr className="bg-gray-200">
                              <th className="text-left p-3">Peso:</th>
                              <td className="text-center p-3">{states.productObj?.weight}</td>
                            </tr>
                            <tr className="bg-gray-100">
                              <th className="text-left p-3">Largura:</th>
                              <td className="text-center p-3">{states.productObj?.width}</td>
                            </tr>
                            <tr className="bg-gray-200">
                              <th className="text-left p-3">Altura:</th>
                              <td className="text-center p-3">{states.productObj?.heigth}</td>
                            </tr>
                            <tr className="bg-gray-100">
                              <th className="text-left p-3">Profundidade:</th>
                              <td className="text-center p-3">{states.productObj?.depth}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Descrição</h1>
                    <div
                      className="text-lg text-gray-500"
                      dangerouslySetInnerHTML={{ __html: states.productObj?.description }}
                    />

                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Ficha Técnica</h1>
                    <div
                      className="text-lg text-gray-500"
                      dangerouslySetInnerHTML={{ __html: states.productObj?.technicalInformation }}
                    />
                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Perguntas e respostas</h1>
                  </div>
                  <hr className="h-px bg-slate-500" />
                  <div className="w-full h-auto flex flex-col gap-4 relative">
                    <h1 className="text-3xl">Avaliações do produto</h1>
                  </div>
                </div>
                <div className="w-[30vw] p-2 flex flex-col gap-3">
                  {states.zoom
                    ? (
                      <div
                        className="w-[20vw] h-[20vw] rounded-lg"
                        style={{
                          left: '100%',
                          top: 0,
                          background: `url(${process.env.REACT_APP_BUCKET_URL + states.featuredImage?.imageUrl}) no-repeat`,
                          backgroundSize: '500%',
                          backgroundPosition: `${states.zoomPos.x}% ${states.zoomPos.y}%`
                        }}
                      />
                    )
                    : (
                      <>
                        <div className="w-full justify-between flex items-center">
                          <div className="w-full inline-flex gap-2 text-slate-500">
                            <p>Novo</p>
                            <p>|</p>
                            <p>+50 vendidos</p>
                          </div>
                          <button
                            className="w-[3vh] h-[3vh]"
                            onClick={() => states.setFavoriteProduct && states.setFavoriteProduct(!states.favoriteProduct)}
                          >
                            {
                              states.favoriteProduct
                                ? <Icons.favorite className="w-full h-full text-red-600" />
                                : <Icons.willFavorite className="w-full h-full" />
                            }

                          </button>
                        </div>
                        <div className="w-full flex flex-col gap-1">
                          <div className="flex flex-col space-y-2">
                            <h1 className="font-bold cursor-pointer text-3xl nd:text-2xl font-mono" style={{
                              backgroundImage: `linear-gradient(to right, ${companyFetched?.ThirdColorLight}, ${companyFetched?.thirdColorDark})`,
                              WebkitBackgroundClip: "text",
                              color: "transparent",
                            }}>
                              {states.productObj?.name}
                            </h1>
                          </div>
                          <div className="inline-flex gap-4 items-center">
                            <p className="text-slate-500">4.3</p>
                            <div className="inline-flex gap-1">
                              <Icons.fullStar
                                className="text-blue-800"
                              />
                              <Icons.fullStar
                                className="text-blue-800"
                              />
                              <Icons.fullStar
                                className="text-blue-800"
                              />
                              <Icons.fullStar
                                className="text-blue-800"
                              />
                              <Icons.halfStar
                                className="text-blue-800"
                              />
                            </div>
                            <p className="text-slate-500">(456)</p>
                          </div>
                        </div>
                        <div className="flex flex-col space-y-2 mt-5">
                          <div className="flex flex-col p-2 space-y-2">
                            {
                              states.productObj?.promotionPrice &&
                              <del className="text-gray-400 text-md whitespace-nowrap">{formatToCurrency(states.productObj?.realPrice)}</del>
                            }
                            <div className="inline-flex w-full justify-start gap-2">
                              <span className="text-bold text-4xl whitespace-nowrap">
                                {formatToCurrency(states.productObj?.promotionPrice) ?? formatToCurrency(states.productObj?.realPrice)}
                              </span>
                              {
                                states.productObj?.promotionPrice &&
                                <span className="text-green-600 folt-bold space-x-1 flex items-start">
                                  <span>{calculatePercentageDiscount(states.productObj?.realPrice, states.productObj?.promotionPrice)}%</span>
                                  <span>OFF</span>
                                </span>
                              }
                            </div>
                          </div>
                          <a className="text-blue-700 cursor-pointer">Ver os meios de pagamento</a>
                        </div>
                        <span className="text-lg">Estoque disponível</span>
                      </>
                    )
                  }
                  <div className="flex flex-col gap-2 mt-20">
                    <button
                      className="w-auto h-auto p-2 px-6 border border-[#263e70] rounded-full bg-[#263e70]"
                      onClick={() => null}
                    >
                      <p className="whitespace-nowrap text-white text-base">Comprar</p>
                    </button>
                    <button
                      className="w-auto h-auto p-2 px-6 border border-[#6087db] rounded-full bg-[#6087db]"
                      onClick={() => null}
                    >
                      <p className="whitespace-nowrap text-white text-base">Adicionar ao carrinho</p>
                    </button>
                  </div>
                  <section className="w-full flex-col p-2 mt-20">
                    <div className="rounded-md bg-red-100 h-auto min-h-[50vh] flex text-center justify-center items-center">
                      <h1 className="text-lg">Área destinada a meios de pagamentos</h1>
                    </div>
                  </section>
                  <section className="w-full flex-col p-2 mt-20">
                    <div className="rounded-md bg-yellow-100 h-auto min-h-[50vh] flex text-center justify-center items-center">
                      <h1 className="text-lg">Área destinada a propagandas</h1>
                    </div>
                  </section>
                  <section className="w-full flex-col p-2 mt-20">
                    <div className="rounded-md bg-orange-100 h-auto min-h-[50vh] flex text-center justify-center items-center">
                      <h1 className="text-lg">Área destinada a produtos relacionados</h1>
                    </div>
                  </section>
                </div>
              </div>
              <section className="w-full flex-col p-2">
                <div className="rounded-md bg-purple-100 h-auto min-h-[50vh] flex text-center justify-center items-center">
                  <h1 className="text-lg">Área destinada a produtos mais vendidos</h1>
                </div>
              </section>
            </div>
            <Modal
              open={states.open}
              toggle={states.toggle}
              close={states.close}
              header={false}
              styleBg="w-auto h-auto flex flex-col relative bg-none rounded-t-md"
              module={
                <>
                  <div className="w-screen h-full flex justify-center items-center relative bg-none">
                    <button
                      className="absolute top-2 right-2 z-50"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        states.close();
                      }}
                    >
                      <Icons.close
                        className="text-primary text-red-700"
                        size={50}
                      />
                    </button>
                    <div className="w-[47%] h-[80%] bg-none">
                      {
                        states.featuredImage
                          ? <Image
                            path={process.env.REACT_APP_BUCKET_URL + states.featuredImage?.imageUrl}
                            style="w-full h-full rounded-lg bg-cover"
                          />
                          : <Image path={ImgNoImageAvaible} />
                      }
                    </div>
                  </div>
                </>
              }
            />
          </>
        }
      />
    </>
  )
};