import { useEffect } from "react";
import { RoutesPath } from "../../../../routes/AppRoutes.routes";


type TMyAccProfileShoppingHooksProps = {};
export type TMyAccProfileShoppingHooksStates = TMyAccProfileShoppingHooksProps & {

};

export const MyAccProfileShoppingHooks = (props: TMyAccProfileShoppingHooksProps): TMyAccProfileShoppingHooksStates => {

  useEffect(() => {
    localStorage.setItem('navigateTo', RoutesPath.accountShopping);

  }, []);

  return {
    ...props,
  };
};