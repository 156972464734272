import { StructurePages } from "../_index.pages";

type TContactPageProps = {};

export default function ContactPage({ ...props }: TContactPageProps) {


  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex justify-center items-center p-4">
              <h2 className="text-9xl">
                <strong>
                  Contato
                </strong>
              </h2>
            </div>

          </>
        }
      />
    </>
  )
};