import { TModalProps } from "../Modal";


type TModalHooksProps = TModalProps & {};
type TModalHooksStates = TModalHooksProps & {
  t: (data: string) => any;
};

export const ModalHooks = (props: TModalHooksProps): TModalHooksStates => {

  return {
    ...props
  } as TModalHooksStates;
};