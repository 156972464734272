import { Icons } from "../../assets/icons/_index.icons";
import { Modal } from "../../components/_index.components";
import { useCompanyFetch } from "../../layout/_index.layout";
import { RoutesPath } from "../../routes/AppRoutes.routes";
import {
  RegisterPageHooks,
  StructurePages,
  TRegisterPageHooksStates
} from "../_index.pages";

import { Navigate } from "react-router-dom";

export type TRegisterPageProps = {};

export default function RegisterPage({ ...props }: TRegisterPageProps) {

  const states: TRegisterPageHooksStates = RegisterPageHooks({ ...props } as TRegisterPageProps);

  const { companyFetched } = useCompanyFetch();

  return (
    <>
      {
        states.navigateTo &&
        <Navigate to={states.navigateTo} replace={true} />
      }
      <StructurePages
        hiddeHeader={true}
        hiddeMenu={true}
        hiddeFooter={true}
        module={
          <>
            <div className="w-full h-auto nd:mt-20">
              <div className="w-full h-full flex justify-center items-start">
                <div className="w-auto h-auto rounded-md shadow-md p-16 flex flex-col gap-8 max-w-[50%] nd:p-2 md:p-2 lg:p-2 nd:max-w-full lg:max-w-full md:max-w-full">
                  <h1 className="text-2xl nd:text-lg">Complete os dados para criar sua conta</h1>
                  <ul className="w-auto h-auto flex flex-col">
                    <li className="inline-flex gap-6 items-center shadow-md p-2 rounded-md justify-between min-h-[8vh]">
                      <div className="inline-flex gap-6 items-center">
                        <div className="ring-2 ring-slate-300 rounded-full p-2 h-8 w-8">
                          <Icons.email />
                        </div>
                        <div className="flex flex-col">
                          <span>Adicione seu e-mail</span>
                          <span className="text-xs">Você vai receber informações sobre sua conta.</span>
                        </div>
                      </div>
                      {
                        !states.registerEmail
                          ? <button
                            className="w-auto max-w-[30%] nd:max-w-auto h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                            onClick={() => {
                              states.toggle();
                              states.setShowEmail(true);
                            }}
                          >
                            <p className="whitespace-nowrap text-white text-base">Adicionar</p>
                          </button>
                          : <button
                            className="w-8"
                            onClick={() => {
                              states.toggle();
                              states.setShowEmail(true);
                            }}
                          >
                            <Icons.edit />
                          </button>
                      }

                    </li>
                    <li className="inline-flex gap-6 items-center shadow-md p-2 rounded-md justify-between min-h-[8vh]">
                      <div className="inline-flex gap-6 items-center">
                        <div className="ring-2 ring-slate-300 rounded-full p-2 h-8 w-8">
                          <Icons.person />
                        </div>
                        <div className="flex flex-col">
                          <span>Escolha um nome</span>
                          <span className="text-xs">Ele será mostrado às pessoas que interagem com você.</span>
                        </div>
                      </div>
                      {
                        (
                          !states.registerName ||
                          !states.registerLastname
                        )
                          ? <button
                            className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                            onClick={() => {
                              states.toggle();
                              states.setShowName(true);
                            }}
                          >
                            <p className="whitespace-nowrap text-white text-base">Adicionar</p>
                          </button>
                          : <button
                            className="w-8"
                            onClick={() => {
                              states.toggle();
                              states.setShowName(true);
                            }}
                          >
                            <Icons.edit />
                          </button>
                      }
                    </li>
                    <li className="inline-flex gap-6 items-center shadow-md p-2 rounded-md justify-between min-h-[8vh]">
                      <div className="inline-flex gap-6 items-center">
                        <div className="ring-2 ring-slate-300 rounded-full p-2 h-8 w-8">
                          <Icons.padlock />
                        </div>
                        <div className="flex flex-col">
                          <span>Crie sua senha</span>
                          <span className="text-xs">Para manter sua conta protegida.</span>
                        </div>
                      </div>
                      {
                        !states.registerPassword
                          ? <button
                            className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                            onClick={() => {
                              states.toggle();
                              states.setShowPassword(true);
                            }}
                          >
                            <p className="whitespace-nowrap text-white text-base">Adicionar</p>
                          </button>
                          : <button
                            className="w-8"
                            onClick={() => {
                              states.toggle();
                              states.setShowPassword(true);
                            }}
                          >
                            <Icons.edit />
                          </button>
                      }

                    </li>
                  </ul>
                  {
                    states.showRegister &&
                    <div className="w-full flex justify-center">
                      <button
                        className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                        onClick={states.handleUserRegister}
                      >
                        <p className="whitespace-nowrap text-white text-base">Criar conta</p>
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        }
      />
      <Modal
        close={states.close}
        open={states.open}
        toggle={states.toggle}
        header={false}
        module={
          <div className="flex flex-col items-center shadow-md p-5 rounded-md">
            {
              states.showEmail &&
              <div className="w-full flex gap-6 flex-col 2xl:min-w-[40vw]">
                <div className="h-auto w-full">
                  <h3 className="text-xl">Digite seu e-mail</h3>
                  <p>Certifique-se de que você tenha acesso a ele.</p>
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="email" className="text-base"><i>Email</i></label>
                  <input
                    name="email"
                    className="rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE]"
                    onChange={(e: any) => {
                      states.setRegisterEmail(e.target.value);
                    }}
                    value={states.registerEmail}
                  />
                </div>
                <p className="text-xs">
                  Ao clicar em "Continuar", aceito os
                  <strong
                    onClick={() => states.setNavigateTo(RoutesPath.terms)}
                    className="text-blue-400 ml-1 cursor-pointer mr-1"
                  >
                    Termos e condições
                  </strong>
                  e autorizo o uso dos meus dados de acordo com a
                  <strong
                    onClick={() => states.setNavigateTo(RoutesPath.privacy)}
                    className="text-blue-400 ml-1 cursor-pointer"
                  >
                    Declaração de privacidade.
                  </strong>
                </p>
                <button
                  className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                  onClick={() => {
                    states.close();
                    states.setShowEmail(false)
                  }}
                >
                  <p className="whitespace-nowrap text-white text-base">Continuar</p>
                </button>
              </div>
            }
            {
              states.showName &&
              <div className="w-full flex gap-6 flex-col 2xl:min-w-[40vw]">
                <div className="h-auto w-full">
                  <h3 className="text-xl">Escolha um nome para você ou para seu negócio</h3>
                  <p>Ele será mostrado às pessoas que interagem com você na {companyFetched.name}.</p>
                </div>
                <div className="inline-flex gap-6 w-full">
                  <div className="w-full">
                    <label htmlFor="name" className="text-base"><i>Nome</i></label>
                    <input
                      name="name"
                      className="rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE]"
                      onChange={(e: any) => {
                        states.setRegisterName(e.target.value);
                      }}
                      value={states.registerName}
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="lastname" className="text-base"><i>Sobrenome</i></label>
                    <input
                      name="lastname"
                      className="rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE]"
                      onChange={(e: any) => {
                        states.setRegisterLastname(e.target.value);
                      }}
                      value={states.registerLastname}
                    />
                  </div>
                </div>
                <button
                  className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                  onClick={() => {
                    states.close();
                    states.setShowName(false)
                  }}
                >
                  <p className="whitespace-nowrap text-white text-base">Continuar</p>
                </button>
              </div>
            }
            {
              states.showPassword &&
              <div className="w-full flex gap-6 flex-col 2xl:min-w-[40vw]">
                <div className="h-auto w-full">
                  <h3 className="text-xl">Escolha uma senha</h3>
                  <p>Ela ajudará a te manter sua conta protegida.</p>
                </div>
                <div className="inline-flex gap-6 w-full">
                  <div className="w-full">
                    <label htmlFor="password" className="text-base"><i>Senha</i></label>
                    <div className="w-full relative">
                      <input
                        name="password"
                        className="rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE]"
                        onChange={(e: any) => {
                          states.setRegisterPassword(e.target.value);
                        }}
                        type={states.fieldType}
                        value={states.registerPassword}
                      />
                      {
                        states.fieldType === "password" ? (
                          <Icons.eyeInvisible
                            size={20}
                            className="absolute right-2 xl:top-4 nd:top-2 top-3 cursor-pointer"
                            onClick={() =>
                              states.setFieldType && states.setFieldType((prevEstado: any) =>
                                prevEstado === "password"
                                  ? "text"
                                  : "password"
                              )
                            }
                          />
                        ) : (
                          <Icons.eyeVisible
                            size={20}
                            className="absolute right-2 top-3 nd:top-2 cursor-pointer"
                            onClick={() =>
                              states.setFieldType && states.setFieldType((prevEstado: any) =>
                                prevEstado === "password"
                                  ? "text"
                                  : "password"
                              )
                            }
                          />
                        )
                      }
                    </div>
                  </div>
                  <div className="w-full">
                    <label htmlFor="confirmPassword" className="text-base"><i>Confirmação Senha</i></label>
                    <div className="w-full relative">
                      <input
                        name="confirmPassword"
                        className="rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE]"
                        onChange={(e: any) => {
                          states.setRegisterConfirmPassword(e.target.value);
                        }}
                        type={states.confirmFieldType}
                        value={states.registerConfirmPassword}
                      />
                      {
                        states.fieldType === "password" ? (
                          <Icons.eyeInvisible
                            size={20}
                            className="absolute right-2 xl:top-4 nd:top-2 top-3 cursor-pointer"
                            onClick={() =>
                              states.setFieldType && states.setFieldType((prevEstado: any) =>
                                prevEstado === "password"
                                  ? "text"
                                  : "password"
                              )
                            }
                          />
                        ) : (
                          <Icons.eyeVisible
                            size={20}
                            className="absolute right-2 nd:top-2 top-3 cursor-pointer"
                            onClick={() =>
                              states.setFieldType && states.setFieldType((prevEstado: any) =>
                                prevEstado === "password"
                                  ? "text"
                                  : "password"
                              )
                            }
                          />
                        )
                      }
                    </div>
                  </div>
                </div>
                <button
                  className="w-auto max-w-[30%] h-full p-2 border border-[#93b9d8] rounded-md bg-[#93b9d8]"
                  onClick={() => {
                    states.close();
                    states.setShowPassword(false)
                  }}
                >
                  <p className="whitespace-nowrap text-white text-base">Continuar</p>
                </button>
              </div>
            }
          </div>
        }
      />
    </>
  )
}