import { toast } from "react-toastify";

import { api } from "./_axios.services"

import {
  ICardReg,
  ICardView
} from "../interfaces/_index.interfaces";

export async function GetCardList(item: any) {
  try {
    let query: string = "";
    query = `?user=${item.user}`

    const response = await api.get("card" + query)
    const data: ICardView[] = response.data.data.card_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function RegisterCard(item: ICardReg) {
  try {
    const response = await api.post("card", item)
    const data = response.data;

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};


export async function DeleteCard(item: string) {
  try {
    let query: string = "";
    query = `?card_id=${item}`

    const response = await api.delete("card/" + item)
    const data = response.data;

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};