import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Icons } from "../../../../assets/icons/_index.icons";
import { HooksModal } from "../../../../hooks/_index.hooks";
import { REGEX } from "../../../../utils/_index.utils";
import { CompanyId } from "../../../../layout/_index.layout";

import { ICardReg, ICardView } from "../../../../interfaces/_index.interfaces";
import { DeleteCard, GetCardList, RegisterCard } from "../../../../services/_index.services";
import { RoutesPath } from "../../../../routes/AppRoutes.routes";


type TMyAccProfileCardHooksProps = {};
export type TMyAccProfileCardHooksStates = TMyAccProfileCardHooksProps & {
  cardsList: any[];
  completedCardList: any[];
  open: boolean;
  cardNumber: string;
  cardDueDate: string;
  cardPrintedName: string;
  cardCV: string;
  cardHoldersCPF: string;
  cardNick: string;
  loading: boolean;
  cardFlag: any;
  setCardNumber: (d: string) => void;
  setCardDueDate: (d: string) => void;
  setCardPrintedName: (d: string) => void;
  setCardCV: (d: string) => void;
  setCardHoldersCPF: (d: string) => void;
  setCardNick: (d: string) => void;
  setCardsList: (d: any[]) => void;
  close: () => void;
  toggle: () => void;
  handleFormCard: (e: any) => void;
  handleRemoveCard: (d: ICardView) => void;
};

export const MyAccProfileCardHooks = (props: TMyAccProfileCardHooksProps): TMyAccProfileCardHooksStates => {
  const { open, close, toggle } = HooksModal();

  const [cardNumber, setCardNumber] = useState<string>();
  const [cardDueDate, setCardDueDate] = useState<string>();
  const [cardPrintedName, setCardPrintedName] = useState<string>();
  const [cardCV, setCardCV] = useState<string>();
  const [cardHoldersCPF, setCardHoldersCPF] = useState<string>();
  const [cardNick, setCardNick] = useState<string>();
  const [cardFlag, setCardFlag] = useState<any | null>(null);

  const [completedCardList, setCompletedCardList] = useState<any[] | null>(null);
  const [cardsList, setCardsList] = useState<ICardView[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [reloadList, setReloadList] = useState<boolean>(true);

  useEffect(() => {
    localStorage.setItem('navigateTo', RoutesPath.accountCard);
    if (!reloadList)
      return;

    async function getUserCardList() {
      const userId = JSON.parse(localStorage.getItem('authState'))?.user?._id;
      if (!userId) return toast.warning("Não foi possível identificar a autenticidade da conexão!");

      setLoading(true);
      const resp = await GetCardList({ user: userId });
      setCardsList(resp);
      setReloadList(false);
    };

    getUserCardList();
  }, [reloadList]);

  useEffect(() => {
    setCompletedCardList(cardsList.map(item => ({
      ...item,
      icon: cardPatterns.find(obj => obj.name === item.flag)?.icon
    })));

    setTimeout(() => {
      setLoading(false);
    }, 500)
  }, [cardsList]);

  useEffect(() => {
    const flag = identifyCardFlag(cardNumber)?.icon;
    setCardFlag(flag);
  }, [cardNumber]);

  async function handleFormCard(e: any) {
    e.preventDefault();

    const userId = JSON.parse(localStorage.getItem('authState'))?.user?._id;

    let obj = {
      company: CompanyId,
      dueDate: cardDueDate,
      holder: cardPrintedName,
      holderDocument: cardHoldersCPF?.replace(/[\.\-]/g, ''),
      number: cardNumber,
      securityCode: cardCV,
      user: userId,
      nickCard: cardNick,
      flag: identifyCardFlag(cardNumber)?.name
    } as ICardReg;

    const resp = await RegisterCard(obj);
    if (resp && resp?.success)
      toast.success(resp?.message);
    else
      return toast.error(resp?.message);

    setReloadList(true);
    close();
  };

  async function handleRemoveCard(item: ICardView) {
    const resp = await DeleteCard(item._id);

    if (resp && resp?.success)
      return toast.success(resp?.message);
    else
      return toast.error(resp?.message);
  };

  const cardPatterns = [
    { name: "Amex", pattern: REGEX.Amex, icon: Icons.amex },
    { name: "Aura", pattern: REGEX.Aura, icon: Icons.card },
    { name: "Banese Card", pattern: REGEX.Banese, icon: Icons.card },
    { name: "Cabal", pattern: REGEX.Cabal, icon: Icons.card },
    { name: "Diners", pattern: REGEX.Diners, icon: Icons.diners },
    { name: "Discover", pattern: REGEX.Discover, icon: Icons.discover },
    { name: "Elo", pattern: REGEX.Elo, icon: Icons.card },
    { name: "Fort Brasil", pattern: REGEX.Fort, icon: Icons.card },
    { name: "GrandCard", pattern: REGEX.GrandCard, icon: Icons.card },
    { name: "Hipercard", pattern: REGEX.Hipercard, icon: Icons.card },
    { name: "JCB", pattern: REGEX.JCB, icon: Icons.card },
    { name: "Mastercard", pattern: REGEX.Mastercard, icon: Icons.mastercard },
    { name: "Personal Card", pattern: REGEX.Personal, icon: Icons.card },
    { name: "Sorocred", pattern: REGEX.Sorocred, icon: Icons.card },
    { name: "Valecard", pattern: REGEX.Valecard, icon: Icons.card },
    { name: "Visa", pattern: REGEX.Visa, icon: Icons.visa }
  ];

  function identifyCardFlag(cardNumber: string) {
    for (const card of cardPatterns) {
      if (card.pattern.test(cardNumber?.replace(/ /g, ''))) {
        return {
          name: card.name, icon: <card.icon className="w-full h-full bg-cover" />
        };
      }
    }
    return { name: "Unknown", icon: null };
  }

  return {
    ...props,
    cardsList,
    completedCardList,
    open,
    cardNumber,
    cardDueDate,
    cardPrintedName,
    cardCV,
    cardHoldersCPF,
    cardNick,
    loading,
    cardFlag,
    setCardNumber,
    setCardDueDate,
    setCardPrintedName,
    setCardCV,
    setCardHoldersCPF,
    setCardNick,
    close,
    toggle,
    setCardsList,
    handleFormCard,
    handleRemoveCard,
  };
};