import { ProductCard, Spinner } from "../../../components/_index.components";
import { useCompanyFetch } from "../../../layout/_index.layout";
import { ProductsGridHooks, ProductsPageHooksStates } from "../../_index.pages";


export type TProductsGridProps = ProductsPageHooksStates & {};


export default function ProductsGrid({ ...props }: TProductsGridProps) {

  const states = ProductsGridHooks({ ...props } as TProductsGridProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <section className="w-full">
        <div className="w-full h-full grid grid-cols-3 gap-2">
          {
            states.productsList?.map((item, i) => (
              <div
                key={i}
                className="w-full rounded-lg h-auto min-h-[25vh] flex flex-col gap-2 p-2 cursor-pointer"
                style={{
                  background: companyFetched?.secondaryColorLight,
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  backdropFilter: "blur(5.5px)",
                }}
              >
                <ProductCard
                  item={item} key={i}
                />
              </div>
            ))
          }

        </div>

      </section>
    </>
  )
}