import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { ICompany } from "../interfaces/_index.interfaces";

export async function CompanyRegister(company: ICompany) {
  try {
    const response = await api.post("company", company);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetCompany(_id: string) {
  try {
    const response = await api.get("company/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetCompanyList() {
  try {
    const response = await api.get("company")
    const data = response.data.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function RemoveCompany(_id: string) {
  try {
    const response = await api.delete("company/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateCompany(_id: string, company: any) {
  try {
    const response = await api.put("company/" + _id, company)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};


export async function UpdateCompanyEmployees(_id: string, employees: any) {
  try {
    const response = await api.patch("company/" + _id, employees)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};