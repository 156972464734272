export const formatPhone = (value: string) => {
  if (!value) return value;

  const phoneNumber = value.replace(/\D/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 3) return phoneNumber;
  if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
  return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
};

export const formatRG = (value: string) => {
  if (!value) return value;

  const rg = value.replace(/\D/g, '');
  const rgLength = rg.length;

  if (rgLength <= 2) return rg;
  if (rgLength <= 5) return `${rg.slice(0, 2)}.${rg.slice(2)}`;
  if (rgLength <= 8) return `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(5)}`;
  return `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(5, 8)}-${rg.slice(8, 9)}`;
};

export const formatCPF = (value: string) => {
  if (!value) return value;

  const cpf = value.replace(/\D/g, '');
  const cpfLength = cpf.length;

  if (cpfLength <= 3) return cpf;
  if (cpfLength <= 6) return `${cpf.slice(0, 3)}.${cpf.slice(3)}`;
  if (cpfLength <= 9) return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6)}`;
  return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
};

export const formatCEP = (value: string) => {
  if (!value) return value;

  const cep = value.replace(/\D/g, '');
  const cepLength = cep.length;

  if (cepLength <= 5) return cep;
  return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
};

export const formatCardNumber = (value: string) => {
  if (!value) return value;

  const cardNumber = value.replace(/\D/g, '');
  const cardNumberLength = cardNumber.length;

  if (cardNumberLength <= 4) return cardNumber;
  if (cardNumberLength <= 8) return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4)}`;
  if (cardNumberLength <= 12) return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8)}`;
  return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8, 12)} ${cardNumber.slice(12, 16)}`;
};

export const formatDueDate = (value: string) => {
  const cleaned = value.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

  if (match) {
    return `${match[1]}${match[2] ? '/' + match[2] : ''}`;
  }
  return value;
};

export const formatPrintedName = (value: string) => {
  if (!value) return;

  return value.toUpperCase();
}

export const formatToCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR',
    { style: 'currency', currency: 'BRL' }
  ).format(value);
};