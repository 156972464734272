import { useCompanyFetch } from "../../../layout/_index.layout";

import {
  HomeCategoriesGridHooks,
  THomeCategoriesGridHooksStates,
  THomePageHooksStates
} from "../../_index.pages";

import {
  Image,
  Spinner
} from "../../../components/_index.components";

import ImgNoImageAvaible from "../../../assets/img/noImage.png";

export type THomeCategoriesGridProps = THomePageHooksStates & {};

export default function HomeCategoriesGrid({ ...props }: THomeCategoriesGridProps) {

  const states: THomeCategoriesGridHooksStates = HomeCategoriesGridHooks({ ...props } as THomeCategoriesGridProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <div className="w-full h-auto bg-none grid grid-cols-2 nd:grid-cols-1 gap-5">
        {
          states.categoriesListEmphasis?.map((item, i) => (
            <div
              key={i}
              className="w-full rounded-lg h-auto min-h-[70vh] nd:min-h-[40vh] flex flex-col gap-2 p-2 cursor-pointer"
              style={{
                background: companyFetched?.secondaryColorLight,
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                backdropFilter: "blur(5.5px)",
              }}
            >
              <div className="flex flex-col p-2 space-y-2">
                <h1 className="font-bold cursor-pointer whitespace-nowrap text-5xl nd:text-2xl font-mono" style={{
                  backgroundImage: `linear-gradient(to right, ${companyFetched?.ThirdColorLight}, ${companyFetched?.thirdColorDark})`,
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}>{item?.name}</h1>
              </div>
              {
                item.image?.imageThumbUrl
                  ? <Image
                    path={process.env.REACT_APP_BUCKET_URL + item.image?.imageThumbUrl}
                  />
                  : <Image path={ImgNoImageAvaible} />
              }
            </div>
          ))
        }
      </div>
    </>
  )
};