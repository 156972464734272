import { Navigate } from "react-router-dom";
import { Dropdown } from "../Dropdown/_index.dropdown";

import {
  MenuHooks,
  TMenuHooksStates
} from "./_index.menu";
import { useState } from "react";

export type TMenuProps = {};

export default function Menu({ ...props }: TMenuProps) {

  const states: TMenuHooksStates = MenuHooks({ ...props } as TMenuProps)


  // const [hoveredDropdown, setHoveredDropdown] = useState<boolean>(false);

  return (
    <>
      {
        states.featuredRoute &&
        <Navigate to={states.featuredRoute} replace={true} />
      }
      <section className="w-full h-auto fixed space-y-3 z-50 nd:hidden"
        style={{
          background: "rgba(156, 151, 151, 0.2)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <hr className="w-full h-px bg-slate-500" />
        <menu className="inline-flex gap-8 justify-center items-center w-full relative text-xs">
          {
            states.menuModules.map((item, i) => (
              <button
                className="w-auto relative"
                key={i}
                onMouseEnter={() => {
                  states.setFeaturedId(item._id);
                  states.setFeaturedModule(item.onHover ? <item.onHover {...states} /> : null);
                  states.setHoveredDropdown(true);
                  states.toggle();
                }}
                // onMouseLeave={() => {
                //   states.toggle();
                // }}
                // onClick={() => {
                //   states.setFeaturedId(item._id);
                //   states.setFeaturedModule(item.onHover ? <item.onHover {...states} /> : null);
                //   states.toggle();
                // }}
                onClick={() => states.setFeaturedRoute(item.route)}
              >
                <p>
                  <strong className="text-slate-800">
                    {item.name}
                  </strong>
                </p>
                {
                  (states.hoveredDropdown && states.featuredId === item._id) &&
                  <Dropdown
                    open={states.hoveredDropdown}
                    close={states.close}
                    toggle={states.toggle}
                    module={
                      states.featuredModule
                    }
                  />
                }
              </button>
            ))
          }
        </menu>
        <hr className="w-full h-px bg-slate-500" />
      </section>
    </>
  )
}