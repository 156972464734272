import { toast } from "react-toastify";

import { api } from "./_axios.services"
import { IUser } from "../interfaces/IUser.i";

export async function UserRegister(user: IUser) {
  try {
    const response = await api.post("users", user);
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetUser(_id: string) {
  try {
    const response = await api.get("users/" + _id)
    const data = response.data.data.user;

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function RemoveUser(_id: string) {
  try {
    const response = await api.delete("users/" + _id)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function UpdateUser(_id: string, user: IUser) {
  try {
    const response = await api.put("users/" + _id, user)
    const data = response.data

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};