import { useEffect } from "react";
import { RoutesPath } from "../../../../routes/AppRoutes.routes";


type TMyAccProfilePrivacyHooksProps = {};
export type TMyAccProfilePrivacyHooksStates = TMyAccProfilePrivacyHooksProps & {

};

export const MyAccProfilePrivacyHooks = (props: TMyAccProfilePrivacyHooksProps): TMyAccProfilePrivacyHooksStates => {

  useEffect(() => {
    localStorage.setItem('navigateTo', RoutesPath.accountPrivacy);

  }, []);

  return {
    ...props,
  };
};