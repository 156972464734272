import { toast } from "react-toastify";

import { api } from "./_axios.services"

export async function MediaRegister(media: FormData) {
  try {
    const response = await api.post("image", media);
    const data = response.data.data.image;

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function RemoveMedia(_id: string) {
  try {
    const response = await api.delete("image/" + _id);
    const data = response.data;

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};
