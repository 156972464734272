import { useState } from "react";
import { TDropdownProps } from "../Dropdown";


export type TDropdownHooksStates = {
  open: boolean;
  toggle: () => void;
  close: () => void;
};

export const DropdownHooks = (): TDropdownHooksStates => {
  const [open, setOpen] = useState<boolean>(false);

  function toggle() {
    setOpen(!open);
  };

  function close() {
    setOpen(false);
  };


  return {
    open,
    toggle,
    close,
  } as TDropdownHooksStates;
};