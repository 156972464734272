import axios from "axios";

export async function getCoordinatesFromZipcode(zipcode: string) {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
    const results = response.data

    return results;
  } catch (error) {
    console.log('Ocorreu um erro na requisição:', error);
  }
};

