
import { IProduct } from "../../interfaces/_index.interfaces";
import { calculatePercentageDiscount, formatToCurrency } from "../../utils/_index.utils";
import { ProductsCardsHooks } from "./_index.productCard";
import { Image } from "../_index.components";

import ImgNoImageAvaible from "../../assets/img/noImage.png";
import { RoutesPath } from "../../routes/AppRoutes.routes";

export type TProductCardProps = {
  item: IProduct
};

export default function ProductCard({ ...props }: TProductCardProps) {

  const states = ProductsCardsHooks({ ...props } as TProductCardProps);


  return (
    <>
      <div
        onClick={() => states.navigate(RoutesPath.productDetails.replace(':_id', props.item._id))}
        className="w-full h-full p-2 flex flex-col justify-between items-start gap-2 cursor-pointer hover:text-blue-800"
      >
        {
          states.featuredImage
            ? <Image
              path={process.env.REACT_APP_BUCKET_URL + states.featuredImage?.imageThumbUrl}
              onMouseEnter={states.toggleFeaturedImage}
              onMouseLeave={states.toggleFeaturedImage}
              style="w-full h-[50%] nd:h-[50%] rounded-lg bg-cover"
            />
            : <Image path={ImgNoImageAvaible} />
        }
        <a className="text-md">{props.item?.name}</a>
        <div className="flex flex-col p-2">
          <del className="text-gray-400 text-md whitespace-nowrap">{formatToCurrency(props.item.realPrice)}</del>
          <div className="inline-flex w-full justify-start gap-2">
            <span className="text-bold text-2xl whitespace-nowrap">{formatToCurrency(props.item.promotionPrice)}</span>
            <span className="text-green-600 folt-bold space-x-1 flex items-start">
              <span>{calculatePercentageDiscount(props.item.realPrice, props.item.promotionPrice)}%</span>
              <span>OFF</span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}