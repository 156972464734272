import { useEffect, useState } from "react";

import { THomeBannerProps } from "../Home_Banner";

type THomeBannerHooksProps = THomeBannerProps & {};
export type THomeBannerHooksStates = THomeBannerHooksProps & {
  images: any[];
  activeIndex: number;
  imageBanner: any;
  goToPrevImage: () => void;
  goToNextImage: () => void;
};

export const HomeBannerHooks = ({ ...props }: THomeBannerHooksProps): THomeBannerHooksStates => {
  const [images, setImages] = useState<any[]>([]);
  const [imageBanner, setImageBanner] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (!props.companyFetched?.banner?.length)
      return;

    setImages(props.companyFetched?.banner);
  }, []);

  useEffect(() => {
    if (!images?.length)
      return;

    let currentIndex = 0;
    let count = 0;


    function banner() {
      count++
      setImageBanner(images[currentIndex]);
      setActiveIndex(currentIndex);
      currentIndex = (currentIndex + 1) % images.length;
    }

    const intervalId = setInterval(banner, 1800);

    return () => {
      clearInterval(intervalId);
    };
  }, [images]);

  function goToPrevImage() {
    const prevIndex = (activeIndex - 1 + images.length) % images.length;
    setImageBanner(images[prevIndex]);
    setActiveIndex(prevIndex);
  };

  function goToNextImage() {
    const nextIndex = (activeIndex + 1) % images.length;
    setImageBanner(images[nextIndex]);
    setActiveIndex(nextIndex);
  };


  return {
    ...props,
    images,
    activeIndex,
    imageBanner,
    goToPrevImage,
    goToNextImage,
  };
};