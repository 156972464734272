import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
  BestsellersPage,
  ContactPage,
  CookiesPolicyPage,
  HomePage,
  LaunchPage,
  LoginPage,
  MyAccountPage,
  PrivacyPolicyPage,
  ProductsDetailsPage,
  ProductsPage,
  PromotionsPage,
  RegisterPage,
  TermsOfUsePage
} from "../pages/_index.pages";

export const RoutesPath = {
  dir: "/",
  login: "login",
  home: "/home",
  contact: "/contact",
  products: "/products",
  bestsellers: "/bestsellers",
  promotions: "/promotions",
  launchs: "/launchs",
  cookies: "/cookies",
  privacy: "/privacy",
  terms: "/terms",
  register: "/register",
  account: "/account",
  accountAddress: "/account/address",
  accountProfile: "/account/profile",
  accountCard: "/account/card",
  accountShopping: "/account/shopping",
  accountPrivacy: "/account/privacy",
  productDetails: "/product_details/:_id",
};

export default function AppRoutes() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <Routes>
          <Route path={RoutesPath.dir} element={<HomePage />} />
          <Route path={RoutesPath.home} element={<HomePage />} />
          <Route path={RoutesPath.login} element={<LoginPage />} />
          <Route path={RoutesPath.bestsellers} element={<BestsellersPage />} />
          <Route path={RoutesPath.products} element={<ProductsPage />} />
          <Route path={RoutesPath.promotions} element={<PromotionsPage />} />
          <Route path={RoutesPath.launchs} element={<LaunchPage />} />
          <Route path={RoutesPath.contact} element={<ContactPage />} />
          <Route path={RoutesPath.terms} element={<TermsOfUsePage />} />
          <Route path={RoutesPath.privacy} element={<PrivacyPolicyPage />} />
          <Route path={RoutesPath.cookies} element={<CookiesPolicyPage />} />
          <Route path={RoutesPath.register} element={<RegisterPage />} />
          <Route path={RoutesPath.account} element={<MyAccountPage />} />
          <Route path={RoutesPath.accountAddress} element={<MyAccountPage />} />
          <Route path={RoutesPath.accountProfile} element={<MyAccountPage />} />
          <Route path={RoutesPath.accountCard} element={<MyAccountPage />} />
          <Route path={RoutesPath.accountShopping} element={<MyAccountPage />} />
          <Route path={RoutesPath.accountPrivacy} element={<MyAccountPage />} />
          <Route path={RoutesPath.productDetails} element={<ProductsDetailsPage />} />
        </Routes>
      </Router>
    </>
  )
};