import { Icons } from "../../assets/icons/_index.icons";
import { useCompanyFetch } from "../../layout/_index.layout";
import { Spinner } from "../_index.components";

type TFooterProps = {};

export default function Footer({ ...props }: TFooterProps) {
  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );


  return (
    <>
      <footer className="w-full h-auto px-[22rem] xl:px-[10rem] space-y-5 py-2 nd:px-2 md:px-10 bottom-0"
        style={{
          background: companyFetched?.primaryColorDark,
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >

        <section className="w-full inline-flex gap-32 justify-end nd:w-auto nd:gap-x-28 nd:gap-0 nd:flex-wrap nd:justify-start">
          <div className="text-gray-50 p-2 space-y-5">
            <h3 className="text-lg whitespace-nowrap nd:text-base">Loja</h3>
            <div className="w-full flex flex-col space-y-1 nd:text-xs">
              <a className="cursor-pointer">Promoções</a>
              <a className="cursor-pointer">Lançamentos</a>
              <a className="cursor-pointer">Mais Vendidos</a>
              <a className="cursor-pointer">Produtos</a>
            </div>
          </div>
          <div className="text-gray-50 p-2 space-y-5">
            <h3 className="text-lg whitespace-nowrap nd:text-base">Atendimento ao Cliente</h3>
            <div className="w-full flex flex-col space-y-1 nd:text-xs">
              <a className="cursor-pointer">Entregas e devoluções</a>
              <a className="cursor-pointer">Política da loja</a>
              <a className="cursor-pointer">Métodos de pagamento</a>
              <a className="cursor-pointer">Política de Cookies</a>
              <a className="cursor-pointer">FAQ</a>
            </div>
          </div>
          <div className="text-gray-50 p-2 space-y-5">
            <h3 className="text-lg whitespace-nowrap nd:text-base">Sobre a {companyFetched?.name}</h3>
            <div className="w-full flex flex-col space-y-4 nd:text-xs">
              <div className=" space-y-1 flex flex-col">
                <a className="cursor-pointer">Nossa história</a>
                <a className="cursor-pointer">Marcas e designers</a>
                <a className="cursor-pointer">Contato</a>
              </div>
              <div className="inline-flex w-full justify-between">
                <Icons.whatsapp className="cursor-pointer" />
                <Icons.instagram className="cursor-pointer" />
                <Icons.facebook className="cursor-pointer" />
              </div>
            </div>
          </div>
        </section>


        <div
          className="w-full text-gray-50 px-2 justify-between flex bottom-0 nd:text-xs"
        // style={{
        //   background: "rgba(156, 151, 151, 0.2)",
        //   boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        //   backdropFilter: "blur(5.5px)",
        // }}
        >
          <span>Copyright © 2018 - {new Date().getFullYear()} SeedzTech.</span>
          {/* <span>Métodos de pagamentos aceitos</span> */}
        </div>
      </footer>
    </>
  )
};