import { StructurePages } from "../_index.pages";

type TPromotionsPageProps = {};

export default function PromotionsPage({ ...props }: TPromotionsPageProps) {


  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full flex justify-center items-center">
              <h2 className="text-9xl">
                <strong>
                  Promoções
                </strong>
              </h2>
            </div>

          </>
        }
      />
    </>
  )
};