import { useState } from "react";

export type THooksModalStates = {
  open: boolean;
  toggle: () => void;
  close: () => void;
};

export default function HooksModal(): THooksModalStates {
  const [open, setOpen] = useState<boolean>(false);

  function toggle() {
    setOpen(!open);
  };

  function close() {
    setOpen(false);
  };

  return {
    open,
    toggle,
    close,
  } as THooksModalStates;
};


