import { ReactNode } from "react";
import { Icons } from "../../../assets/icons/_index.icons";

type TBgModalProps = {
  close: () => void;
  text?: string;
  styleBg?: string;
  module?: ReactNode;
  centralizado?: boolean;
  header?: boolean;
};

export default function BgModal(props: TBgModalProps) {


  return (
    <div className="flex justify-center items-center h-full max-w-full overflow-x-auto rounded-md w-auto nd:p-2"
      style={{
        background: "rgba(136, 136, 136, 0.20)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(5.5px)",
      }}
    >
      <div className={props.styleBg ?? "w-auto h-auto flex flex-col relative bg-white rounded-t-md"}>
        {
          (
            props.header === undefined ||
            props.header === true
          ) && (
            <>
              <div className="inline-flex justify-between items-center px-3 pt-2 bg-none">
                <h2 className='text-xl'>
                  <strong>
                    {props.text}
                  </strong>
                </h2>
                <button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    props.close();
                  }}
                >
                  <Icons.close
                    className="text-primary hover:text-red-700"
                    size={30}
                  />
                </button>
              </div>
              <div className='h-px bg-header-background' />
            </>
          )
        }
        {props.module}
      </div>
    </div>
  )
};