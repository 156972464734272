import { Filters, Spinner } from "../../components/_index.components";

import {
  ProductsGrid,
  ProductsPageHooks,
  ProductsPageHooksStates,
  StructurePages
} from "../_index.pages";

export type TProductsPageProps = {};

export default function ProductsPage({ ...props }: TProductsPageProps) {

  const states: ProductsPageHooksStates = ProductsPageHooks({ ...props } as TProductsPageProps);

  return (
    <>
      <StructurePages
        module={
          <>

            <div className="w-full h-full bg-none p-2 inline-flex gap-32  py-[5vh]">
              {
                states.loading
                  ? <Spinner />
                  : <>
                    <div className="w-auto h-auto flex flex-col gap-10">
                      <h1 className="text-5xl">
                        <strong>
                          Produtos
                        </strong>
                      </h1>
                      <Filters {...states} />

                    </div>
                    <ProductsGrid {...states} />
                  </>
              }
            </div>
          </>
        }
      />
    </>
  )
};