import { Icons } from "../../../assets/icons/_index.icons";

import {
  GENDER,
  MyAccProfileDataHooks,
  TMyAccProfileDataHooksStates
} from "../../_index.pages";

import {
  Image,
  Input,
  Selection,
  Spinner
} from "../../../components/_index.components";

export type TMyAccProfileDataProps = {};

export default function MyAccProfileData({ ...props }: TMyAccProfileDataProps) {

  const states: TMyAccProfileDataHooksStates = MyAccProfileDataHooks(props as TMyAccProfileDataProps);

  return (
    <>
      <form
        className="w-full h-full flex flex-col gap-12 nd:p-2"
        onSubmit={states.handleProfileFormSubmit}
      >
        <div className="inline-flex w-full h-auto justify-end nd:flex nd:flex-col nd:justify-center nd:items-center">
          <div className="w-full h-auto py-10">
            <p className="text-lg font-bold">{(states.profileName + ' ' + states.profileLastName) ?? "-"}</p>
            <p>{states.authState?.user?.email ?? "-"}</p>
            <p>{states.profileBirthday?.split("-")?.reverse()?.join("/") ?? "-"}</p>
          </div>
          <div className="w-full flex justify-end nd:justify-center">
            <div
              className="rounded-full mt-2 ring-1 ring-gray-300 h-[18vh] w-[18vh] flex justify-center items-center cursor-pointer"
              onClick={states.handleFile}
            >
              {
                !states.uploadingImg
                  ? states.imageUrl &&
                  <Image
                    renderer={states.fileKey}
                    path={states.imageUrl}
                    style="bg-cover w-full h-full rounded-full"
                  />
                  : <Spinner />
              }
              {
                !states.uploadingImg &&
                !states.imageUrl &&
                <Icons.upload
                  className="text-gray-500"
                  size={50}
                />
              }
              <input
                type="file"
                hidden
                ref={states.fileRef}
                onChange={(e) => {
                  const files = e.target.files;
                  if (files && files.length > 0) {
                    states.setProfileImage(files[0]);
                    states.setFileKey(states.fileKey + 1)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <hr className="w-full h-px bg-gray-300" />
        <div className="w-full h-auto flex flex-col gap-6">
          <div className="inline-flex w-full gap-6 nd:flex nd:flex-col">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="name" className="w-auto whitespace-nowrap">Nome</label>
              <Input
                id={"teste"}
                name={"name"}
                type={"text"}
                setValue={states.setProfileName}
                value={states.profileName}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="lastName" className="w-auto whitespace-nowrap">Sobrenome</label>
              <Input
                id={"teste"}
                name={"lastName"}
                type={"text"}
                setValue={states.setProfileLastName}
                value={states.profileLastName}
              />
            </div>
          </div>
          <div className="inline-flex w-full gap-6 nd:flex nd:flex-col">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="documentRG" className="w-auto whitespace-nowrap">RG</label>
              <Input
                id={"teste"}
                name={"documentRG"}
                type={"text"}
                placeholder="xx.xxx.xxx-x"
                setValue={states.setProfileDocument}
                value={states.profileDocument}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="documentCPF" className="w-auto whitespace-nowrap">CPF</label>
              <Input
                id={"teste"}
                name={"documentCPF"}
                type={"text"}
                placeholder="xxx.xxx.xxx-xx"
                setValue={states.setProfileDocumentCPF}
                value={states.profileDocumentCPF}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="birthday" className="w-auto whitespace-nowrap">Aniversário</label>
              <Input
                id={"teste"}
                name={"birthday"}
                type={"date"}
                setValue={states.setProfileBirthday}
                value={states.profileBirthday}
              />
            </div>
          </div>
          <div className="inline-flex w-full gap-6 nd:flex nd:flex-col">
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="gender" className="w-auto whitespace-nowrap">Gênero</label>
              <Selection
                optionsList={GENDER}
                setValue={states.setProfileGender}
                value={states.profileGender}
              />
            </div>
            <div className="w-full flex flex-col gap-2 ">
              <label htmlFor="phone" className="w-auto whitespace-nowrap">Telefone</label>
              <Input
                id={"teste"}
                name={"phone"}
                type={"tel"}
                placeholder="(xx) xxxxx-xxxx"
                setValue={states.setProfilePhone}
                value={states.profilePhone}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          <button
            className="w-auto h-auto p-2 px-6 border border-[#00A650] rounded-full bg-[#00A650]"
            onClick={states.handleProfileFormSubmit}
          >
            <p className="whitespace-nowrap text-white text-base">Salvar</p>
          </button>
        </div>
      </form>
    </>
  )
};