
export const USER_TYPE = {
  ADMIN: 'ADMIN',
  PREMIUM: 'PREMIUM',
  FREE: 'FREE',
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER',
};

export const USER_STATUS = {
  BLOCK: 'BLOCK',
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED'
};