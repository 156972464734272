import { useEffect, useState } from "react";
import { ICategory } from "../../../interfaces/ICategories";

import {
  TPrices,
} from "../../../data/_index.data";

import { GetCategoryList, GetProductList } from "../../../services/_index.services";
import { IProduct } from "../../../interfaces/IProduct.i";
import { CompanyId } from "../../../layout/_index.layout";

type ProductsPageHooksProps = {};
export type ProductsPageHooksStates = ProductsPageHooksProps & {
  categoryList: ICategory[];
  pricesList: TPrices[];
  productsList: IProduct[];
  loading: boolean;
}


export const ProductsPageHooks = ({ ...props }: ProductsPageHooksProps): ProductsPageHooksStates => {

  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [pricesList, setPricesList] = useState<TPrices[]>([]);
  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    async function getCategoriesList() {
      const resp = await GetCategoryList({ company: CompanyId });
      setCategoryList(resp);
    }

    async function getProductList() {
      const resp = await GetProductList({ company: CompanyId });
      setProductsList(resp);
      const priceIntervals = createPriceIntervals(resp);
      setPricesList(priceIntervals);
      setLoading(false);
    }

    getCategoriesList();
    getProductList();
  }, []);

  const createPriceIntervals = (products: IProduct[], interval: number = 50) => {
    const priceMap = new Map<string, number>();


    products.forEach(product => {
      const { realPrice } = product;
      const lowerBound = Math.floor(realPrice / interval) * interval;
      const upperBound = lowerBound + interval;
      const intervalKey = `R$ ${lowerBound.toFixed(2)} - R$ ${upperBound.toFixed(2)}`;

      if (priceMap.has(intervalKey)) {
        priceMap.set(intervalKey, priceMap.get(intervalKey)! + 1);
      } else {
        priceMap.set(intervalKey, 1);
      }
    });

    const sortedPriceArray = Array.from(priceMap.entries()).sort(([a], [b]) => {
      const lowerBoundA = parseFloat(a.split('-')[0].replace('R$', '').trim());
      const lowerBoundB = parseFloat(b.split('-')[0].replace('R$', '').trim());
      return lowerBoundA - lowerBoundB;
    });

    return sortedPriceArray.map(([price, amount], index) => {
      let prices = price;

      if (index === 0)
        prices = `... até ${price.split('-')[1]}`

      return ({
        _id: index.toString(),
        price: prices,
        amount,
      })
    });
  };





  return {
    ...props,
    categoryList,
    pricesList,
    productsList,
    loading,
  }
}