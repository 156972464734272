import {
  Spinner,
  Image,
} from "../../../components/_index.components";

import { useCompanyFetch } from "../../../layout/_index.layout";
import { calculatePercentageDiscount, formatToCurrency } from "../../../utils/_index.utils";

import ImgNoImageAvaible from "../../../assets/img/noImage.png";

import {
  HomeProductOfferDayHooks,
  THomePageHooksStates,
  TTHomeProductOfferDayHooksStates
} from "../../_index.pages";
import { RoutesPath } from "../../../routes/AppRoutes.routes";


export type THomeProductOfferDayProps = THomePageHooksStates & {};

export default function HomeProductOfferDay({ ...props }: THomeProductOfferDayProps) {

  const states: TTHomeProductOfferDayHooksStates = HomeProductOfferDayHooks({ ...props } as THomeProductOfferDayProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <div
        onClick={() => states.navigate(RoutesPath.productDetails.replace(':_id', props.productOfferDay._id))}
        className="w-[30%] nd:w-auto rounded-lg h-auto xl:h-[60vh] 2xl:h-[50vh] flex flex-col gap-2"
        style={{
          background: companyFetched?.secondaryColorLight,
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <div className="w-[30%] h-full px-4 pt-4">
          <h3 className="text-xl whitespace-nowrap">
            <strong>
              Oferta do dia
            </strong>
          </h3>
        </div>
        <div className="w-full h-full p-2 flex flex-col justify-start items-start gap-2 cursor-pointer hover:text-blue-800">
          {
            states.featuredImage
              ? <Image
                path={process.env.REACT_APP_BUCKET_URL + states.featuredImage?.imageThumbUrl}
                onMouseEnter={states.toggleFeaturedImage}
                onMouseLeave={states.toggleFeaturedImage}
                style="w-full h-[60%] rounded-lg bg-cover"
              />
              : <Image path={ImgNoImageAvaible} />
          }
          <a className="text-lg">{props.productOfferDay?.name}</a>
          <div className="flex flex-col p-2 gap-2">
            <del className="text-gray-400 text-md">{formatToCurrency(props.productOfferDay?.realPrice)}</del>
            <div className="inline-flex w-full justify-start gap-2">
              <span className="text-bold text-3xl">{formatToCurrency(props.productOfferDay?.promotionPrice)}</span>
              <span className="text-green-600 folt-bold space-x-1 flex items-start">
                <span>{calculatePercentageDiscount(props.productOfferDay?.realPrice, props.productOfferDay?.promotionPrice)}%</span>
                <span>OFF</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}