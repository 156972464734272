import { TProductsGridProps } from "../ProductsGrid";


type TProductsGridHooksProps = TProductsGridProps & {};
export type TProductsGridHooksStates = TProductsGridHooksProps & {

}

export const ProductsGridHooks = ({ ...props }: TProductsGridHooksProps): TProductsGridHooksStates => {
  return {
    ...props,
  };
}