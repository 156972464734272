import { useEffect, useState } from "react";

import { TMyAccountPageProps } from "../MyAccountPage";
import { RoutesPath } from "../../../routes/AppRoutes.routes";
import { _ID } from "../../../utils/_index.utils";
import { Icons } from "../../../assets/icons/_index.icons";

import {
  TCompanyFetched,
  useCompanyFetch
} from "../../../layout/_index.layout";

import {
  MyAccProfileAddress,
  MyAccProfileCard,
  MyAccProfileData,
  MyAccProfilePrivacy,
  MyAccProfileShopping
} from "../_index.myaccount";


type TMyAccountPageHooksProps = TMyAccountPageProps & {};
export type TMyAccountPageHooksStates = TMyAccountPageHooksProps & {
  navigateTo: string;
  profileMenu: TProfileMenu[];
  companyFetched: TCompanyFetched;
  mouseEvent: string | null;
  profileMenuFeatured: string | null;
  setProfileMenuFeatured: (d: string | null) => void;
  setNavigateTo: (d: string) => void;
  setMouseEvent: (d: string | null) => void;
  handleLogout: () => void;
};

export type TProfileMenu = {
  _id: string;
  name: string;
  icon: any;
  module: any;
  route?: string;
};

export const MyAccountPageHooks = ({ ...props }: TMyAccountPageHooksProps): TMyAccountPageHooksStates => {
  const profileMenu: TProfileMenu[] = [
    {
      name: "Dados pessoais",
      _id: _ID.profile_data,
      icon: Icons.profile,
      module: MyAccProfileData,
      route: RoutesPath.accountProfile,
    },
    {
      name: "Endereços",
      _id: _ID.profile_address,
      icon: Icons.street,
      module: MyAccProfileAddress,
      route: RoutesPath.accountAddress,
    },
    {
      name: "Compras",
      _id: _ID.profile_shopping,
      icon: Icons.shoppingCart,
      module: MyAccProfileShopping,
      route: RoutesPath.accountShopping,
    },
    {
      name: "Cartões",
      _id: _ID.profile_cards,
      icon: Icons.card,
      module: MyAccProfileCard,
      route: RoutesPath.accountCard,
    },
    {
      name: "Privacidade",
      _id: _ID.profile_privacy,
      icon: Icons.privacy,
      module: MyAccProfilePrivacy,
      route: RoutesPath.accountPrivacy,
    },
  ];

  const path = (localStorage.getItem('navigateTo'));
  const currentRoute =
    window.location.pathname === path
      ? profileMenu.find(item => item.route === path)?._id
      : profileMenu[0]._id;

  const [navigateTo, setNavigateTo] = useState<string>("");
  const [mouseEvent, setMouseEvent] = useState<string | null>(currentRoute);
  const [profileMenuFeatured, setProfileMenuFeatured] = useState<string | null>(currentRoute);

  const { companyFetched } = useCompanyFetch();

  function handleLogout() {
    localStorage.removeItem('authState');
    window.location.reload();
    setNavigateTo(RoutesPath.home);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('authState'))?.token)
      return;

    window.location.pathname = RoutesPath.login;
    setNavigateTo(window.location.pathname);

  }, []);

  useEffect(() => {
    const item = profileMenu.find(item => item._id === profileMenuFeatured);
    if (item)
      setNavigateTo(item.route);

  }, [profileMenuFeatured, profileMenu]);

  return {
    ...props,
    navigateTo,
    profileMenu,
    companyFetched,
    mouseEvent,
    profileMenuFeatured,
    setProfileMenuFeatured,
    setNavigateTo,
    setMouseEvent,
    handleLogout,
  };
};