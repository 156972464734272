import { useEffect, useState } from "react";
import { THomeProductOfferDayProps } from "../Home_ProductOfferDay";
import { useNavigate } from "react-router-dom";


type THomeProductOfferDayHooksProps = THomeProductOfferDayProps & {};
export type TTHomeProductOfferDayHooksStates = {
  featuredImage: any;
  navigate: (d: string) => any;
  setFeaturedImageIndex: (data: number) => void;
  toggleFeaturedImage: () => void;
};

export const HomeProductOfferDayHooks = ({ ...props }: THomeProductOfferDayHooksProps): TTHomeProductOfferDayHooksStates => {
  const navigate = useNavigate();

  const [featuredImageIndex, setFeaturedImageIndex] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  useEffect(() => {
    if (!props.productOfferDay) return;

    setFeaturedImageIndex(0);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.productOfferDay]);

  function toggleFeaturedImage() {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    } else {
      const id = setInterval(() => {
        setFeaturedImageIndex((prevIndex) => (prevIndex + 1) % props.productOfferDay.image.length);
      }, 600);

      setIntervalId(id);
    }
  }


  return {
    ...props,
    featuredImage: props.productOfferDay ? props.productOfferDay.image[featuredImageIndex] : null,
    navigate,
    setFeaturedImageIndex,
    toggleFeaturedImage,
  };
};