
import './index.css';

import { AppRoutes } from './routes/_index.routes';
import { CompanyFetchedProvider } from './layout/CompanyColors';
import { AuthProvider } from './pages/_index.pages';

export default function AppRoot() {

  return (
    <CompanyFetchedProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </CompanyFetchedProvider>
  );
};