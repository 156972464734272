import { useEffect, useState } from "react";
import { TProductCardProps } from "../ProductCard";
import { useNavigate } from "react-router-dom";

type TProductsCardsHooksProps = TProductCardProps & {};
type TProductsCardsHooksStates = TProductsCardsHooksProps & {
  featuredImage: any;
  setFeaturedImageIndex: (data: number) => void;
  toggleFeaturedImage: () => void;
  navigate: (d: string) => any;
};

export const ProductsCardsHooks = ({ ...props }: TProductsCardsHooksProps): TProductsCardsHooksStates => {
  const [featuredImageIndex, setFeaturedImageIndex] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.item) return;

    setFeaturedImageIndex(0);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.item]);

  function toggleFeaturedImage() {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    } else {
      const id = setInterval(() => {
        setFeaturedImageIndex((prevIndex) => (prevIndex + 1) % props.item.image.length);
      }, 600);

      setIntervalId(id);
    }
  }

  return {
    ...props,
    featuredImage: props.item ? props.item.image[featuredImageIndex] : null,
    setFeaturedImageIndex,
    toggleFeaturedImage,
    navigate,
  };
};