import { TInputProps } from "../Input";

type TInputHooksProps = TInputProps & {};
type TInputHooksStates = TInputHooksProps & {
  modelDataStructure: (d: string) => void;
};

export const InputHooks = (props: TInputHooksProps): TInputHooksStates => {

  function modelDataStructure(data: string) {
    if (props.currency) {
      const parsedValue = parseFloat(data);
      if (isNaN(parsedValue)) return;

      const cleanedValue = data.replace(/,|\./g, '');
      const newValue = cleanedValue.slice(0, -1) + data[data.length - 1];
      const formattedValue = (parseFloat(newValue) / 100).toFixed(2);
      return props.setValue && props.setValue(formattedValue);
    }

    return props.setValue && props.setValue(data);
  };

  return {
    ...props,
    modelDataStructure,
  } as TInputHooksStates;
};