import { Spinner } from "../../components/_index.components";
import { useCompanyFetch } from "../../layout/_index.layout";
import StructurePages from "../StructurePages";

import {
  HomeBanner,
  HomeCategoriesGrid,
  HomePageHooks,
  HomeProductOfferDay,
  HomeProductOffersGrid,
  THomePageHooksStates
} from "../_index.pages";

type THomePageProps = {};

export default function HomePage({ ...props }: THomePageProps) {

  const states: THomePageHooksStates = HomePageHooks({ ...props } as THomePageProps);

  const { companyFetched, loading } = useCompanyFetch();

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <StructurePages
        module={
          <>
            <div className="w-full h-full space-y-14 p-4">
              {
                states.loading
                  ? <Spinner />
                  : <>
                    {
                      companyFetched?.banner?.length > 0 &&
                      <HomeBanner
                        companyFetched={companyFetched}
                        loading={loading}
                      />
                    }
                    <div className="inline-flex nd:flex nd:flex-col w-full gap-5 justify-center items-center h-auto">
                      <HomeProductOfferDay
                        {...states}
                      />
                      <div
                        className="w-[70%] nd:w-auto rounded-lg h-auto xl:h-[60vh] 2xl:h-[50vh] gap-2"
                        style={{
                          background: companyFetched?.secondaryColorLight,
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          backdropFilter: "blur(5.5px)",
                        }}
                      >
                        <div className="w-[70%] p-4">
                          <h3 className="text-xl whitespace-nowrap">
                            <strong>
                              Ofertas do dia
                            </strong>
                          </h3>
                        </div>
                        <HomeProductOffersGrid
                          {...states}
                        />
                      </div>
                    </div>
                    <HomeCategoriesGrid
                      {...states}
                    />
                  </>
              }
            </div>
          </>
        }
      />
    </>
  )
};

