import { useState } from "react";
import { Navigate } from "react-router-dom";

import { useCompanyFetch } from "../../../layout/_index.layout";
import { Icons } from "../../../assets/icons/_index.icons";
import { IMenuSubCategories } from "../../../interfaces/IMenuCategories.i";

import {
  TMenuHooksStates,
  MenuProdutosHooks,
  Spinner
} from "../../_index.components";
import { RoutesPath } from "../../../routes/AppRoutes.routes";

export type TMenuProdutosProps = TMenuHooksStates & {};

export default function MenuProdutos({ ...props }: TMenuProdutosProps) {

  const states = MenuProdutosHooks({ ...props } as TMenuProdutosProps);

  const [subCategoriesList, setSubCategoriesList] = useState<IMenuSubCategories[]>([]);

  const [featuredCategory, setFeaturedCategory] = useState<string>("");
  const [featuredSubCategory, setFeaturedSubCategory] = useState<string>("");

  const [categoryHover, setCategoryHover] = useState<boolean>(false);
  const [subCategoryHover, setSubCategoryHover] = useState<boolean>(false);



  if (states.loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      {/* {
        // !props.module &&
        <Navigate to={RoutesPath.products} replace={true} />
      } */}
      <div
        className="w-full h-auto p-4 flex justify-start items-start"
        onMouseLeave={() => {
          states.setHoveredDropdown(false);
        }}
        style={{
          background: states.companyFetched?.secondaryColorLight,
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5.5px)",
        }}
      >
        <ul className="flex flex-col gap-4 text-start">
          {
            states.menuFetched.categoriesList.map((item, i) => (
              <li
                className="text-xs w-auto inline-flex justify-between items-center text-center gap-8 rounded-md p-2 whitespace-nowrap"
                style={{
                  transition: "background-color 0.5s",
                  background: (categoryHover && (item._id == featuredCategory)) ? states.companyFetched?.secondaryColorDark : "none",
                  color: (categoryHover && (item._id == featuredCategory)) ? "#fff" : "#000"
                }}
                key={i}
                onMouseEnter={() => {
                  setCategoryHover(true);
                  setFeaturedCategory(item._id);
                  setSubCategoriesList(item.subCategories);
                  states.subDropDown.toggle();
                }}
                // onMouseLeave={() => {
                //   setSubCategoriesList([]);
                //   setActiveCategory(null)
                // }}
                onClick={() => null}
              >
                {item.category}
                {
                  item.subCategories?.length > 0 &&
                  <Icons.next />
                }
              </li>
            ))
          }
        </ul>
        {
          subCategoriesList?.length > 0 &&
          <ul
            className="flex flex-col gap-4 text-start ml-5 top-0 right-0 w-auto h-auto min-w-[9vw]"
          >
            {
              subCategoriesList?.map((sub, j) => (
                <li
                  className="text-xs w-auto inline-flex justify-between items-center text-center gap-8 whitespace-nowrap rounded-md hover:text-white p-2"
                  key={j}
                  style={{
                    transition: "background-color 0.5s",
                    background: (categoryHover && (sub._id == featuredSubCategory)) ? states.companyFetched?.secondaryColorDark : "none",
                    color: (categoryHover && (sub._id == featuredSubCategory)) ? "#fff" : "#000"
                  }}
                  onMouseEnter={() => {
                    setSubCategoryHover(true);
                    setFeaturedSubCategory(sub._id);
                  }}
                >
                  {
                    sub.subCategory
                  }
                </li>
              ))
            }
          </ul>
        }
      </div>
    </>
  );
};