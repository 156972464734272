type TImageProps = {
  path: string;
  renderer?: number;
  onMouseEnter?: (d: any) => void;
  onMouseLeave?: (d: any) => void;
  onMouseMove?: (d: any) => void;
  style?: string;
};

export default function Image(props: TImageProps) {
  return (
    <img
      crossOrigin='anonymous'
      src={props.path}
      key={props.renderer}
      className={props.style ?? "bg-cover w-full h-full rounded-md"}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseMove={props.onMouseMove}
      alt="Imagem"
    />
  );
};
