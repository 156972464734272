import React from 'react';
import { InputHooks } from "../_index.components";
import { formatCEP, formatCPF, formatDueDate, formatPhone, formatPrintedName, formatRG } from '../../utils/_index.utils';

export type TInputProps = {
  type: string;
  id: string;
  name: string;
  inValue?: boolean;
  readonly?: boolean;
  placeholder?: string;
  value: string;
  setValue: (data: string) => void;
  style?: string;
  currency?: boolean;
}

export default function Input(props: TInputProps) {
  const states = InputHooks({ ...props } as TInputProps);

  const formatCardNumber = (value: string) => {
    if (!value) return value;

    const cardNumber = value.replace(/\D/g, '');
    const cardNumberLength = cardNumber.length;

    if (cardNumberLength <= 4) return cardNumber;
    if (cardNumberLength <= 8) return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4)}`;
    if (cardNumberLength <= 12) return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8)}`;
    return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8, 12)} ${cardNumber.slice(12, 16)}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;

    if (props.type === 'tel') {
      formattedValue = formatPhone(e.target.value);
    } else if (props.name === 'documentRG') {
      formattedValue = formatRG(e.target.value);
    } else if (props.name === 'documentCPF') {
      formattedValue = formatCPF(e.target.value);
    } else if (props.name === 'postal_code') {
      formattedValue = formatCEP(e.target.value);
    } else if (props.name === 'cardNumber') {
      formattedValue = formatCardNumber(e.target.value);
    } else if (props.name === 'dueDate') {
      formattedValue = formatDueDate(e.target.value);
    } else if (props.name === 'cardPrintedName') {
      formattedValue = formatPrintedName(e.target.value)
    }

    states.modelDataStructure(formattedValue);
  };

  return (
    <>
      <div className="w-full relative">
        <input
          type={states.type}
          id={states.id}
          name={states.name}
          placeholder={states.placeholder}
          className={`
            w-full h-auto min-h-[5vh] rounded-md px-2 
            bg-slate-200 ${props.inValue === false ? "ring-2 ring-negative" : "ring-0"}
            ${props.readonly === true ? "pointer-events-none" : ""}
            ${props.style ?? props.style}
            ${states.currency ? "pl-8" : "pl-none"}`
          }
          onChange={handleChange}
          value={states.value}
        />
        {states.currency && <span className="absolute left-2 top-3">R$</span>}
      </div>
    </>
  );
}
