import { ReactNode } from 'react';
import { DropdownHooks } from './_index.dropdown';

export type TDropdownProps = {
  open: boolean;
  module?: ReactNode;
  style?: string;
  toggle: () => void;
  close: () => void;
};

export default function Dropdown(props: TDropdownProps) {

  // const states = DropdownHooks({ props.toggle, props.close, props.open });

  return (
    <>
      {
        props.open && (
          <div className={`absolute z-20 text-primary mt-4 ${props.style ?? props.style}`}>
            {/* <div className="fixed top-1/2 left-1/2 -translate-x-1/2 bg-opacity-50 -translate-y-1/2 h-screen w-full bg-black z-10 backdrop-blur-sm overflow-y-auto"> */}

            <div className="flex justify-center items-center h-full max-w-full overflow-x-auto rounded-md w-auto"
              style={{
                background: "rgba(136, 136, 136, 0.20)",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                backdropFilter: "blur(5.5px)",
              }}
            >
              <div className='w-auto h-auto flex flex-col relative'>
                {props.module}
              </div>
            </div>
          </div>

        )
      }
    </>
  );
};
