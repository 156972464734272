import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetProduct, GetProductList } from "../../../services/products.services";
import { IProduct } from "../../../interfaces/IProduct.i";
import HooksModal from "../../../hooks/modal.hooks";
import { GetCategoryList } from "../../../services/categories.services";
import { CompanyId } from "../../../layout/CompanyColors";



type TProductsDetailsPageHooksProps = {};
export type TProductsDetailsPageHooksStates = TProductsDetailsPageHooksProps & {
  productObj: IProduct;
  featuredImage: any;
  featuredImageIndex: any;
  favoriteProduct: boolean;
  zoom: boolean;
  zoomPos: any;
  open: boolean;
  productList: IProduct[];
  currentPage: number;
  productsPerPage: number;
  prevPage: () => void;
  nextPage: () => void;
  close: () => void;
  toggle: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  handleMouseMove: (d: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  setZoom: (d: boolean) => void;
  setZoomPos: (d: any) => void;
  setFavoriteProduct: (d: boolean) => void;
  setFeaturedImageIndex: (data: number) => void;
};

export const ProductsDetailsPageHooks = ({ ...props }: TProductsDetailsPageHooksProps): TProductsDetailsPageHooksStates => {
  const { open, close, toggle } = HooksModal();
  const { _id } = useParams();

  const [zoom, setZoom] = useState<boolean>(false);
  const [zoomPos, setZoomPos] = useState<any>({ x: 0, y: 0 });
  const [featuredImageIndex, setFeaturedImageIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [productObj, setProductObj] = useState<IProduct>();
  const [productList, setProductList] = useState<IProduct[]>([]);

  const [favoriteProduct, setFavoriteProduct] = useState<boolean>(false);

  const productsPerPage = 4;
  const totalPages = Math.ceil(productList?.length / productsPerPage);

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages - 1 ? 0 : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 0 ? totalPages - 1 : currentPage - 1);
  };



  useEffect(() => {
    if (!_id) return;

    async function getProduct() {
      let resp = await GetProduct(_id);
      setProductObj(resp);
    };

    getProduct();
  }, [_id]);

  const handleMouseEnter = () => setZoom(true);
  const handleMouseLeave = () => setZoom(false);
  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPos({ x, y });
  };

  useEffect(() => {
    if (!productObj)
      return;

    async function getProductList() {
      let resp = await GetProductList({ company: CompanyId, category: productObj?.category });
      setProductList(resp);
    };

    getProductList();
  }, [productObj]);

  return {
    ...props,
    productObj,
    featuredImage: productObj ? productObj.image[featuredImageIndex] : null,
    featuredImageIndex,
    favoriteProduct,
    zoom,
    zoomPos,
    open,
    productList,
    currentPage,
    productsPerPage,
    prevPage,
    nextPage,
    close,
    toggle,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    setZoom,
    setZoomPos,
    setFavoriteProduct,
    setFeaturedImageIndex,
  };
};