import { createContext, useContext, useState, useEffect } from 'react';
import { Authentication } from '../../../services/_index.services';
import { IUser } from '../../../interfaces/IUser.i';

const AuthContext = createContext(null);

export type TAuthState = {
  user: IUser;
  token: string;
  isAuthenticated: boolean;
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState<TAuthState>({
    user: null,
    token: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedAuthState = localStorage.getItem('authState');
    if (storedAuthState) {
      setAuthState(JSON.parse(storedAuthState));
    }
    setLoading(false);
  }, []);

  const login = async (email: string, password: string) => {
    const resp = await Authentication({ email, password });
    if (!resp) return;

    const token = resp.data.auth.token;
    const user = resp.data.user;

    const newAuthState = {
      user,
      token,
      isAuthenticated: true,
    };

    setAuthState(newAuthState);
    localStorage.setItem('authState', JSON.stringify(newAuthState));
  };

  const logout = () => {
    setAuthState({
      user: null,
      token: null,
      isAuthenticated: false,
    });
    localStorage.removeItem('authState');
  };

  const getToken = () => authState.token;

  return (
    <AuthContext.Provider value={{ authState, setAuthState, login, logout, getToken, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
