import { StructurePages } from "../_index.pages";

import Cookies from "../../assets/img/Cookies.svg";

export type TCookiesPolicyPageProps = {};

export default function CookiesPolicyPage({ ...props }: TCookiesPolicyPageProps) {
  window.scrollTo(0, 0);


  return (
    <>
      <StructurePages
        module={
          <>
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:gap-16 mt-8 nd:mt-0 md:mt-0 lg:mt-16 py-20">
              <h1 className="text-5xl nd:text-3xl">Política de Cookies</h1>
              <div className="w-full inline-flex gap-32">
                <img
                  className="w-1/3 nd:hidden"
                  src={Cookies}
                  alt="cookies"
                />
                <div className="w-1/2 text-lg nd:w-full nd:px-5">
                  <p>
                    A SeedzTech utiliza cookies para melhorar o desempenho e a sua experiência ao utilizar nosso site.
                    Nós procuramos, assim, explicar de forma transparente como, quando e porque utilizamos cookies.
                  </p>
                  <br />
                  <p>
                    Ao acessar o nosso site, você autoriza o uso cookies nos termos desta Política
                  </p>
                  <br />
                  <p>
                    Caso não concorde com o uso de cookies dessa forma, você poderá: (i) ajustar as configurações
                    de seu navegador para não permitir o uso de cookies ou (ii) não acessar o nosso site.
                  </p>
                  <br />
                  <p>
                    Lembramos que desabilitar o uso de cookies poderá impactar na sua experiência ao navegar em nosso site.
                  </p>
                  <br />
                  <p>
                    Para quaisquer questões ou dúvidas sobre esta Política de Cookies, você poderá entrar em
                    contato conosco por meio do endereço eletrônico: comercial@seedztech.com.br
                  </p>
                </div>
              </div>

            </section>
          </>
        }
      />
    </>
  )
};