import { Navigate } from "react-router-dom";

import { RoutesPath } from "../../routes/AppRoutes.routes";
import { Icons } from "../../assets/icons/_index.icons";

import StructurePages from "../StructurePages";
import ImgCompanyLogo from "../../assets/img/logo.png";

import {
  LoginPageHooks,
  TLoginPageHooksStates
} from "./_index.login"


export type TLoginPageProps = {};

export default function LoginPage({ ...props }) {

  const states: TLoginPageHooksStates = LoginPageHooks({ ...props } as TLoginPageProps);

  // if (states.authState?.isAuthenticated)
  //   return <Navigate to={RoutesPath.home} replace={true} />;

  return (
    <>
      {
        states.navigateTo &&
        <Navigate to={states.navigateTo} replace={true} />
      }
      <StructurePages
        hiddeHeader={true}
        hiddeMenu={true}
        hiddeFooter={true}
        module={
          <>
            <div className="w-full h-full 2xl:min-h-[70vh] nd:mt-20">
              <form
                className='flex flex-col justify-center items-center bg-none w-full h-full p-2'
                onSubmit={states.handleLogin}
              >
                <div
                  className='flex grid-cols-2 nd:grid-cols-none nd:flex nd:flex-col md:grid-cols-none md:flex md:flex-col lg:grid-cols-none lg:flex lg:flex-col w-full h-full rounded-lg'
                  style={{
                    background: states.companyFetched?.secondaryColorLight,
                    boxShadow: "0px 1px 4px 0px rgba(31, 23, 23, 0.25)",
                    backdropFilter: "blur(5.5px)",
                  }}
                >
                  <div className='flex xl:flex-col nd:flex-col md:flex-col lg:flex-col 2xl:flex-col justify-end items-end w-full rounded-l-md bg-white py-5'>
                    <div className="w-full h-full nd:hidden md:hidden lg:hidden">
                      <img
                        className="bg-cover w-full h-full"
                        src={ImgCompanyLogo}
                        alt="companyLogo"
                      />
                    </div>
                    <div className="flex w-full h-auto px-5 py-1 gap-2 nd:flex-col">
                      <p className="text-xl">Ainda não possui uma conta na {states.companyFetched?.name}?</p>
                      <a
                        className="text-xl text-blue-400 cursor-pointer nd:text-center"
                        onClick={() => states.setNavigateTo(RoutesPath.register)}
                      >
                        Registre-se
                      </a>
                    </div>
                    <div className="flex w-full h-auto px-5 py-1 gap-2 nd:mt-10 md:mt-10">
                      <a className="text-lg text-blue-400 cursor-pointer">Preciso de ajuda com outros temas</a>
                    </div>
                  </div>
                  <div className='flex xl:flex-col 2xl:flex-col justify-center items-center w-full rounded-r-md'>
                    <div className='flex flex-col w-full p-5 gap-8'>
                      <div className='w-full flex text-center justify-center text-2xl'>
                        <p className="whitespace-nowrap nd:text-lg md:text-lg lg:text-lg">
                          <strong
                            style={{
                              color: states.companyFetched?.primaryColorDark
                            }}
                          >
                            {`Seja bem vindo a ${states.companyFetched?.name}`}
                          </strong>
                        </p>
                      </div>
                      <div className='flex flex-col w-full justify-center items-center'>
                        <input
                          className={`rounded-md px-2 w-full h-[5vh] bg-[#E8F0FE] ${states.inEmail ? "ring-2 ring-[#a53e3e]" : "ring-0"}`}
                          placeholder='Digite seu e-mail...'
                          onChange={(e: any) => {
                            states.setEmail(e.target.value);
                            states.setInEmail(false);
                          }}
                        />
                        <div className='w-full h-[5vh] relative'>
                          <input
                            className={`rounded-md px-2 w-full mt-2 h-full bg-[#E8F0FE] ${states.inPassword ? "ring-2 ring-[#a53e3e]" : "ring-0"}`}
                            placeholder='Digite sua senha...'
                            onChange={(e: any) => {
                              states.setPassword(e.target.value);
                              states.setInPassword(false);
                            }}
                            name="senha"
                            type={states.fieldType}
                            onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                states.handleLogin(e);
                              }
                            }}
                            style={{
                              borderRadius: "0.625rem",
                            }}
                          />
                          {states.fieldType === "password" ? (
                            <Icons.eyeInvisible
                              size={20}
                              className="absolute right-2 xl:top-4 top-6 cursor-pointer"
                              onClick={() =>
                                states.setFieldType((prevEstado: any) =>
                                  prevEstado === "password"
                                    ? "text"
                                    : "password"
                                )
                              }
                            />
                          ) : (
                            <Icons.eyeVisible
                              size={20}
                              className="absolute right-2 top-6 cursor-pointer"
                              onClick={() =>
                                states.setFieldType((prevEstado: any) =>
                                  prevEstado === "password"
                                    ? "text"
                                    : "password"
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className='flex justify-between items-center px-6 gap-6'>
                        <button className="text-white w-auto">
                          <p className='text-blue-400 whitespace-nowrap'>Esqueci a senha</p>
                        </button>
                        <button
                          className="w-auto rounded-full bg-[#26c59f] p-1 px-10 text-white h-auto min-h-[5vh] flex justify-center items-center"
                          type="submit"
                        >
                          <p className='text-lg'>Login</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        }
      />
    </>
  )
}