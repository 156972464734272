import { THomeCategoriesGridProps } from "../Home_CategoriesGrid"

type THomeCategoriesGridHooksProps = THomeCategoriesGridProps & {}
export type THomeCategoriesGridHooksStates = THomeCategoriesGridHooksProps & {

}

export const HomeCategoriesGridHooks = ({ ...props }: THomeCategoriesGridHooksProps): THomeCategoriesGridHooksStates => {

  return {
    ...props,

  }
}