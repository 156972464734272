import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCompanyFetch } from "../layout/_index.layout";
import { RoutesPath } from "../routes/AppRoutes.routes";

import {
  Footer,
  Header,
  Menu,
  Spinner
} from "../components/_index.components";
import { useState } from "react";
import { SYS_GERGELIM_WEB } from "../utils/_index.utils";

export type TStructurePages = {
  module: React.ReactNode;
  hiddeMenu?: boolean;
  hiddeHeader?: boolean;
  hiddeLogo?: boolean;
  hiddeFooter?: boolean;
};

export default function StructurePages(props: TStructurePages) {
  const { companyFetched, loading } = useCompanyFetch();

  const [naviteTo, setNavigateTo] = useState<string>("");
  const [hideWarning, setHideWarning] = useState<boolean>(
    localStorage.getItem(SYS_GERGELIM_WEB + RoutesPath.cookies) === "true"
      ? true
      : false
  );

  function handleHideWarning(e: any) {
    e.preventDefault();

    localStorage.setItem(
      SYS_GERGELIM_WEB + RoutesPath.cookies,
      "true"
    );

    setHideWarning(true);
  };


  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {
        naviteTo &&
        <Navigate to={naviteTo} replace={true} />
      }
      <div className={`h-full bg-[${companyFetched?.primaryColorLight}] relative`}>
        <Header {...props} />
        {
          !props.hiddeMenu &&
          <Menu />
        }
        <div className="relative bg-none w-full h-full mt-[5vh] nd:mt-5 overflow-y-scroll">
          <div className="flex justify-end w-full h-auto">
            <div className="flex justify-start items-start w-full bg-none p-1">
              <div className="flex flex-col bg-datagrid-background w-full h-full rounded-md items-center justify-center px-[22rem] xl:px-[10rem] nd:px-0 md:px-0 lg:px-0">
                {
                  props.module
                }
              </div>
            </div>
          </div>
        </div>
        {
          !hideWarning &&
          <div className="fixed bottom-5 left-5 nd:left-2 nd:right-2 shadow-md w-auto h-auto bg-[#e0e9dc] rounded-md p-2 z-50">
            <div className="inline-flex gap-8 nd:gap-4 justify-center items-center">
              <p className="text-xs w-full text-gray-400">
                Utilizamos cookies para melhorar a sua experiência em nosso site.
                Ao continuar navegando você concorda com a nossa política de privacidade.
                <strong
                  onClick={() => setNavigateTo(RoutesPath.privacy)}
                  className="text-blue-400 ml-1 cursor-pointer"
                >
                  Saiba mais
                </strong>
              </p>
              <button
                className="w-auto max-w-[30%] h-full p-2 border border-[#A8D696] rounded-md bg-[#A8D696]"
                onClick={handleHideWarning}
              >
                <p className="whitespace-nowrap text-white text-xs">Estou de acordo</p>
              </button>
            </div>
          </div>
        }
        {
          !props.hiddeFooter &&
          <Footer />
        }
      </div>

    </>
  )
};