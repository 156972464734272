import { toast } from "react-toastify";

import { api } from "./_axios.services"

export async function GetProduct(_id: string) {
  try {
    const response = await api.get("product/" + _id)
    const data = response.data.data.product;

    return data;
  } catch (error: any) {
    toast.error(error.message)
  };
};

export async function GetProductList(item?: any) {
  try {
    let query: string = "";
    if (item?.company) query = `?company=${item.company}`
    if (item?.category) query = `?category=${item.category}`

    const response = await api.get("product" + query)
    const data = response.data.data.product_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export type TProductsFilter = {
  company?: string;
  category?: string;
  status?: string;
}

export async function GetProductListWithFilter(filter: TProductsFilter) {
  try {
    const response = await api.post("product/filter", filter)
    const data = response.data.data.product_list

    return data;
  } catch (error: any) {
    toast.error(error.message);
  };
};

export async function GetProductListStock(item?: any) {
  try {
    let query: string = "";
    if (item?.company) query = `?company=${item.company}`

    const response = await api.get("product/stock" + query)
    const data = response.data.data.product_list

    let newList = [];
    if (Array.isArray(data)) {
      newList = data.map(({ name, _id, ...obj }) => ({
        value: _id,
        label: name,
        name,
        _id,
        ...obj
      }));
    };

    return newList;
  } catch (error: any) {
    toast.error(error.message);
  };
};
