import React from "react";
import { Icons } from "../../../assets/icons/_index.icons";
import { Input, Modal, NoData, Spinner } from "../../../components/_index.components";
import {
  MyAccProfileCardHooks,
  TMyAccProfileCardHooksStates
} from "./Hooks/MyAccProfileCardHooks";

export type TMyAccProfileCardProps = {};

export default function MyAccProfileCard({ ...props }: TMyAccProfileCardProps) {

  const states: TMyAccProfileCardHooksStates = MyAccProfileCardHooks(props as TMyAccProfileCardProps);

  return (
    <>
      <div className="w-full h-auto justify-center items-center flex flex-col gap-5 nd:mt-5">
        <div className="w-full justify-end flex">
          <button
            className="w-auto h-auto p-2 px-6 border border-[#93b9d8] rounded-full bg-[#93b9d8]"
            onClick={states.toggle}
          >
            <p className="whitespace-nowrap text-white text-base">Incluir</p>
          </button>
        </div>
        <ul className="w-full h-auto">
          {
            states.loading
              ? <Spinner />
              : states.completedCardList?.length > 0
                ? states.completedCardList?.map((item, i) => (
                  <li key={i} className="w-full h-full inline-flex justify-between items-center gap-6 shadow-md p-2">
                    <div className="w-full h-auto inline-flex gap-6 items-center">
                      <div className="w-[8vh] h-[8vh] ring-1 ring-gray-500 rounded-full p-5">
                        {
                          item.icon &&
                          <item.icon className="bg-cover w-full h-full " />
                        }
                      </div>
                      <div className="w-auto h-auto">
                        <p className="text-lg">Terminando em {item.terminatedNumber}</p>
                        <p className="text-gray-400">{item.flag}</p>
                        <p className="text-gray-400">Vencimento: {item.dueDate}</p>
                      </div>
                    </div>
                    <div className="w-auto justify-end items-center p-2">
                      <button
                        onClick={() => states.handleRemoveCard(item)}
                      >
                        <p className="whitespace-nowrap text-[#a53e3e] text-base">Excluir</p>
                      </button>
                    </div>
                  </li>
                ))
                : <NoData />
          }
        </ul>
      </div>
      <Modal
        close={states.close}
        open={states.open}
        toggle={states.toggle}
        module={
          <form
            className="items-center shadow-md px-10 py-5 rounded-md min-w-[50vw] nd:min-w-0 nd:px-1 nd:p-2"
            onSubmit={states.handleFormCard}
          >
            <div className="inline-flex w-full h-full gap-8 nd:p-2 md:flex md:flex-col lg:flex lg:flex-col">
              <div className="w-full flex flex-col gap-2 h-auto">
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="cardNumber" className="w-auto whitespace-nowrap"><strong className="text-red-700">*</strong> Número do cartão</label>
                  <Input
                    id={"teste"}
                    name={"cardNumber"}
                    type={"text"}
                    setValue={states.setCardNumber}
                    value={states.cardNumber}
                  />
                </div>
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="cardPrintedName" className="w-auto whitespace-nowrap"><strong className="text-red-700">*</strong> Nome do titular</label>
                  <Input
                    id={"teste"}
                    name={"cardPrintedName"}
                    type={"text"}
                    setValue={states.setCardPrintedName}
                    value={states.cardPrintedName}
                  />
                </div>
                <div className="inline-flex gap-6">
                  <div className="w-full flex flex-col gap-2 ">
                    <label htmlFor="dueDate" className="w-auto whitespace-nowrap"><strong className="text-red-700">*</strong> Data de vencimento</label>
                    <Input
                      id={"teste"}
                      name={"dueDate"}
                      type={"text"}
                      placeholder="MM/AA"
                      setValue={states.setCardDueDate}
                      value={states.cardDueDate?.toUpperCase()}
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2 ">
                    <label htmlFor="cv" className="w-auto whitespace-nowrap"><strong className="text-red-700">*</strong> Código de segurança</label>
                    <Input
                      id={"teste"}
                      name={"cv"}
                      type={"text"}
                      setValue={states.setCardCV}
                      value={states.cardCV}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="documentCPF" className="w-auto whitespace-nowrap"><strong className="text-red-700">*</strong> CPF do titular do cartão</label>
                  <Input
                    id={"teste"}
                    name={"documentCPF"}
                    type={"text"}
                    setValue={states.setCardHoldersCPF}
                    value={states.cardHoldersCPF}
                  />
                </div>
                <hr className="w-full h-px bg-slate-300" />
                <div className="w-full flex flex-col gap-2 ">
                  <label htmlFor="cardNick" className="w-auto whitespace-nowrap">Apelido do cartão</label>
                  <Input
                    id={"teste"}
                    name={"cardNick"}
                    type={"text"}
                    setValue={states.setCardNick}
                    value={states.cardNick}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center items-center h-auto nd:hidden">
                <div className="rounded-lg min-w-[90%] min-h-[50%] bg-gradient-to-r from-indigo-500 to-indigo-300 p-4">
                  <div className="w-full h-auto flex flex-col gap-20">
                    <div className="rounded-md h-[4vh] w-[3vw] md:w-[8vw] lg:w-[8vw] filter backdrop-brightness-125">
                      {
                        states.cardFlag && states.cardFlag

                      }
                    </div>
                    <div className="w-full flex flex-col gap-2 text-white">
                      <p className="text-4xl">{states.cardNumber ?? "**** **** **** ****"}</p>
                      <div className="w-full justify-between inline-flex">
                        <p>{states.cardPrintedName?.toUpperCase() ?? "NOME E SOBRENOME"}</p>
                        <p>{states.cardDueDate ?? "MM/AA"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end items-center nd:mt-2 md:mt-5 lg:mt-5">
              <button
                type="submit"
                className="w-auto h-auto p-2 px-6 border border-[#00A650] rounded-full bg-[#00A650]"
                onClick={states.handleFormCard}
              >
                <p className="whitespace-nowrap text-white text-base">Salvar</p>
              </button>
            </div>
          </form>
        }
      />
    </>
  )
};