import { IProduct } from "../../../interfaces/IProduct.i";
import { calculatePercentageDiscount } from "../../../utils/_index.utils";
import { TFiltersProps } from "../Filters";

type TFiltersHooksProps = TFiltersProps & {};
export type TFiltersHooksStates = TFiltersHooksProps & {
};

export const FiltersHooks = ({ ...props }: TFiltersHooksProps): TFiltersHooksStates => {

  return {
    ...props,
  };
}