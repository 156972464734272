import { useEffect, useState } from "react";
import { TRegisterPageProps } from "../RegisterPage";
import { HooksModal } from "../../../hooks/_index.hooks";
import { UserRegister } from "../../../services/_index.services";
import { IUser } from "../../../interfaces/IUser.i";
import { USER_STATUS, USER_TYPE } from "../../../utils/_index.utils";
import { RoutesPath } from "../../../routes/AppRoutes.routes";

type TRegisterPageHooksProps = TRegisterPageProps & {};
export type TRegisterPageHooksStates = TRegisterPageHooksProps & {
  registerEmail: string;
  registerName: string;
  registerLastname: string;
  registerPassword: string;
  registerConfirmPassword: string;
  navigateTo: string;
  showEmail: boolean;
  showName: boolean;
  showPassword: boolean;
  showRegister: boolean;
  open: boolean;
  fieldType: any;
  confirmFieldType: any;
  setFieldType: (d: any) => void;
  setConfirmFieldType: (d: any) => void;
  setNavigateTo: (d: string) => void;
  setRegisterEmail: (d: string) => void;
  setRegisterName: (d: string) => void;
  setRegisterLastname: (d: string) => void;
  setRegisterPassword: (d: string) => void;
  setRegisterConfirmPassword: (d: string) => void;
  setShowEmail: (d: boolean) => void;
  setShowName: (d: boolean) => void;
  setShowPassword: (d: boolean) => void;
  handleUserRegister: () => void;
  close: () => void;
  toggle: () => void;
}

export const RegisterPageHooks = ({ ...props }: TRegisterPageHooksProps): TRegisterPageHooksStates => {
  const { open, close, toggle } = HooksModal();

  const [registerEmail, setRegisterEmail] = useState<string>("");
  const [registerName, setRegisterName] = useState<string>("");
  const [registerLastname, setRegisterLastname] = useState<string>("");
  const [registerPassword, setRegisterPassword] = useState<string>("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState<string>("");

  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [showName, setShowName] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRegister, setShowRegister] = useState<boolean>(false);

  const [navigateTo, setNavigateTo] = useState<string>("");
  const [fieldType, setFieldType] = useState<any>("password");
  const [confirmFieldType, setConfirmFieldType] = useState<any>("password");

  useEffect(() => {
    function handleValidForm(): any {
      if (
        registerEmail &&
        registerName &&
        registerLastname &&
        registerPassword &&
        registerConfirmPassword
      ) return setShowRegister(true);

      return setShowRegister(false);
    };

    handleValidForm();
  }, [
    registerEmail,
    registerName,
    registerLastname,
    registerPassword,
    registerConfirmPassword,
  ]);


  async function handleUserRegister() {

    const resp = await UserRegister({
      name: registerName,
      lastname: registerLastname,
      email: registerEmail,
      password: registerPassword,
      type: USER_TYPE.CUSTOMER,
      status: USER_STATUS.ACTIVE,
    } as IUser);

    if (!resp?.data)
      return;

    const token = resp.data.auth.token;
    const user = resp.data.user;

    const newAuthState = {
      user,
      token,
      isAuthenticated: true,
    };

    localStorage.setItem('authState', JSON.stringify(newAuthState));
    setNavigateTo(RoutesPath.home);

    return true;
  };



  return {
    ...props,
    registerEmail,
    registerName,
    registerLastname,
    registerPassword,
    registerConfirmPassword,
    showEmail,
    showName,
    showPassword,
    showRegister,
    navigateTo,
    open,
    fieldType,
    confirmFieldType,
    handleUserRegister,
    setConfirmFieldType,
    setFieldType,
    close,
    toggle,
    setNavigateTo,
    setShowEmail,
    setShowName,
    setShowPassword,
    setRegisterEmail,
    setRegisterName,
    setRegisterLastname,
    setRegisterPassword,
    setRegisterConfirmPassword,
  }
}