import axios from "axios";


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(async config => {
  const token = ""
  config.headers.Authorization = `Bearer ${token}`;
  // config.headers["Content-Type"] = "multipart/form-data";

  return config;
});

export { api }


