import { useState } from 'react';
import { ProductCard } from '../../../components/_index.components';
import { THomePageHooksStates } from '../../_index.pages';
import { Icons } from '../../../assets/icons/_index.icons';

type TProductGridProps = THomePageHooksStates & {};

export default function HomeProductOffersGrid({ ...props }: TProductGridProps) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const productsPerPage = 4;

  const totalPages = Math.ceil(props.productsOfferList?.length / productsPerPage);

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages - 1 ? 0 : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 0 ? totalPages - 1 : currentPage - 1);
  };

  return (
    <div>
      <section className="w-full h-full nd:h-auto nd:overflow-hidden nd:gap-4 grid grid-cols-4 nd:grid-cols-2">
        {
          props.productsOfferList
            ?.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage)
            ?.map((item, index) => (
              <ProductCard
                item={item} key={index}
              />
            ))
        }
      </section>


      <div className="absolute top-0 bottom-0 left-0 flex items-center">
        <button
          className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -ml-10 nd:-ml-5"
          onClick={prevPage}
        >
          <Icons.previous size={50} />
        </button>
      </div>
      <div className="absolute top-0 bottom-0 right-0 flex items-center">
        <button
          className="text-5xl nd:text-xs nd:p-0 p-4 bg-black bg-opacity-10 text-white flex justify-center items-center rounded-full -mr-10 nd:-mr-5"
          onClick={nextPage}
        >
          <Icons.next size={50} />
        </button>
      </div>


      {/* <div className="flex justify-between mt-4">
        <button onClick={prevPage}>Anterior</button>
        <button onClick={nextPage}>Próximo</button>
      </div> */}
    </div>
  );
}
