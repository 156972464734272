import { useState } from "react";

import { TMenuProdutosProps } from "../MenuProdutos";
import { DropdownHooks, TDropdownHooksStates } from "../../../Dropdown/Hooks/_index.hooks";
import { TCompanyFetched, TMenuFeatched, useCompanyFetch } from "../../../../layout/_index.layout";

type TMenuProdutosHooksProps = TMenuProdutosProps & {};
export type TMenuProdutosHooksStates = TMenuProdutosHooksProps & {
  subDropDown: TDropdownHooksStates;
  categoryHover: boolean;
  subCategoryHover: boolean;
  companyFetched: TCompanyFetched;
  menuFetched: TMenuFeatched;
  loading: boolean;
  setCategoryHover: (data: boolean) => void;
  setSubCategoryHover: (data: boolean) => void;
};


export const MenuProdutosHooks = ({ ...props }: TMenuProdutosHooksProps): TMenuProdutosHooksStates => {
  const { companyFetched, menuFetched, loading } = useCompanyFetch();

  const subDropDown = DropdownHooks()

  const [categoryHover, setCategoryHover] = useState<boolean>(false);
  const [subCategoryHover, setSubCategoryHover] = useState<boolean>(false);

  return {
    ...props,
    subDropDown,
    categoryHover,
    subCategoryHover,
    companyFetched,
    menuFetched,
    loading,
    setCategoryHover,
    setSubCategoryHover,
  } as TMenuProdutosHooksStates;
};