import { useEffect, useRef, useState } from "react";
import { TMyAccProfileDataProps } from "../MyAccProfileData";
import { TAuthState, useAuth } from "../../../_index.pages";
import { MediaRegister, UpdateUser } from "../../../../services/_index.services";
import { IUser } from "../../../../interfaces/IUser.i";
import { toast } from "react-toastify";
import { CompanyId } from "../../../../layout/_index.layout";
import { formatCPF, formatPhone, formatRG } from "../../../../utils/_index.utils";

export const GENDER = [
  { value: "MASCULINE_CIS", label: "Cisgênero Masculino" },
  { value: "FEMININE_CIS", label: "Cisgênero Feminino" },
  { value: "MASCULINE_TRANS", label: "Transgênero Masculino" },
  { value: "FEMININE_TRANS", label: "Transgênero Feminino" },
  { value: "GENDER_NEUTRAL", label: "Gênero Neutro" },
  { value: "NON_BINARY", label: "Não Binário" },
  { value: "AGENDER", label: "Agênero" },
  { value: "PANGENDER", label: "Pangênero" },
  { value: "DONT_IDENTIFY", label: "Não me identifico com nenhum dos gêneros" },
  { value: "RATHHER_NOT_ANSWER", label: "Prefiro não responder" },
];

type TMyAccProfileDataHooksProps = TMyAccProfileDataProps & {};
export type TMyAccProfileDataHooksStates = TMyAccProfileDataHooksProps & {
  profileImage: any;
  fileRef: any;
  fileKey: number;
  uploadingImg: boolean;
  imageUrl: string;
  profileName: string;
  profileBirthday: string;
  profileDocument: string;
  profileDocumentCPF: string;
  profileGender: string;
  profilePhone: string;
  profileLastName: string;
  authState: TAuthState;
  setProfileName: (d: string) => void;
  setProfileLastName: (d: string) => void;
  setProfileBirthday: (d: string) => void;
  setProfileDocument: (d: string) => void;
  setProfileDocumentCPF: (d: string) => void;
  setProfileGender: (d: string) => void;
  setProfilePhone: (d: string) => void;
  setImageUrl: (d: string) => void;
  setFileKey: (d: number) => void;
  setUploadingImg: (d: boolean) => void;
  setProfileImage: (d: any) => void;
  handleFile: () => void;
  handleProfileFormSubmit: (d: any) => void;
};

export const MyAccProfileDataHooks = (props: TMyAccProfileDataHooksProps): TMyAccProfileDataHooksStates => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const { authState } = useAuth();

  const [objImg, setObjImg] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [fileKey, setFileKey] = useState<number>(0);
  const [uploadingImg, setUploadingImg] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>(null);

  const [profileName, setProfileName] = useState<string>("");
  const [profileLastName, setProfileLastName] = useState<string>("");
  const [profileBirthday, setProfileBirthday] = useState<string>("");
  const [profileDocument, setProfileDocument] = useState<string>("");
  const [profileDocumentCPF, setProfileDocumentCPF] = useState<string>("");
  const [profileGender, setProfileGender] = useState<string>("");
  const [profilePhone, setProfilePhone] = useState<string>("");

  function handleFile() {
    if (fileRef.current) {
      fileRef.current.click();
    };
  };

  useEffect(() => {
    if (!authState) return;

    setProfileName(authState.user?.name);
    setProfileLastName(authState.user?.lastname);
    setProfileBirthday(new Date(authState.user?.birthday)?.toISOString()?.split('T')[0] ?? new Date()?.toISOString()?.split('T')[0]);
    setProfileDocument(formatRG(authState.user?.document_rg));
    setProfileDocumentCPF((formatCPF(authState.user?.document)));
    setProfileGender(authState.user?.gender);
    setProfilePhone(formatPhone(authState.user?.phone));
    setObjImg(authState.user?.image);
  }, [authState]);

  useEffect(() => {
    if (!objImg)
      return;

    let fileName = objImg.imageUrl;

    const serverPath = process.env.REACT_APP_BUCKET_URL;
    const fullImageUrl = `${serverPath}${fileName}`;

    setImageUrl(fullImageUrl);
    setUploadingImg(false);
  }, [objImg]);

  useEffect(() => {
    if (!profileImage)
      return;

    setUploadingImg(true);
    const form = new FormData();
    form.append("file", profileImage);
    form.append("company", CompanyId);
    form.append("user", authState.user?._id);

    async function uploadMedia() {
      const resp = await MediaRegister(form);
      if (!resp) {
        setUploadingImg(false);
        setFileKey(fileKey + 1);
        return toast.error("Não foi possível inserir a imagem de perfil, por favor contate o administrador do site.");
      }

      let user = authState.user;
      user.image = resp._id;
      await UpdateUser(authState.user?._id, user);

      if (resp) {
        setObjImg(resp);
        setFileKey(fileKey + 1);
      }

      authState.user.image = resp;
      localStorage.setItem('authState', JSON.stringify(authState));
      window.location.reload();
    };

    uploadMedia();
  }, [
    profileImage
  ]);

  async function handleProfileFormSubmit(e: any) {
    e.preventDefault();

    if (!authState.user?._id)
      return toast.error("Usuário inválido!")

    let obj = {
      _id: authState.user._id,
      email: authState.user.email,
      lastname: profileLastName,
      name: profileName,
      birthday: new Date(profileBirthday),
      company: CompanyId,
      document: profileDocumentCPF?.replace(/[\.\-]/g, ''),
      document_rg: profileDocument?.replace(/[\.\-]/g, ''),
      gender: profileGender,
      phone: profilePhone?.replace(/[\(\)\-]/g, ''),
    } as IUser;

    const resp = await UpdateUser(authState.user._id, obj);
    if (resp && resp?.data?.user?._id)
      toast.success(resp.message)
    else
      return toast.error(resp?.message)

    authState.user = resp.data.user;
    localStorage.setItem('authState', JSON.stringify(authState));
    window.location.reload();
  };

  return {
    ...props,
    fileRef,
    profileImage,
    fileKey,
    uploadingImg,
    imageUrl,
    profileName,
    profileBirthday,
    profileDocument,
    profileDocumentCPF,
    profileGender,
    profilePhone,
    profileLastName,
    authState,
    setProfileLastName,
    setProfileName,
    setProfileBirthday,
    setProfileDocument,
    setProfileDocumentCPF,
    setProfileGender,
    setProfilePhone,
    setImageUrl,
    setUploadingImg,
    setFileKey,
    setProfileImage,
    handleFile,
    handleProfileFormSubmit,
  };
};