import { NoData } from "../../../components/_index.components";

import {
  MyAccProfileShoppingHooks,
  TMyAccProfileShoppingHooksStates
} from "./Hooks/MyAccProfileShoppingHooks";

export type TMyAccProfileShoppingProps = {};

export default function MyAccProfileShopping({ ...props }: TMyAccProfileShoppingProps) {

  const states: TMyAccProfileShoppingHooksStates = MyAccProfileShoppingHooks({ ...props } as TMyAccProfileShoppingProps);

  return (
    <>
      <NoData />
    </>
  )
};