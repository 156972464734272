import { useState } from "react";
import { TLoginPageProps } from "../LoginPage";
import { toast } from "react-toastify";
import { TAuthState, useAuth } from "../../_index.pages";
import { TCompanyFetched, useCompanyFetch } from "../../../layout/_index.layout";
import { RoutesPath } from "../../../routes/AppRoutes.routes";

type TLoginPageHooksProps = TLoginPageProps & {};
export type TLoginPageHooksStates = TLoginPageHooksProps & {
  email: string;
  password: string;
  fieldType: string;
  inEmail: boolean;
  inPassword: boolean;
  companyFetched: TCompanyFetched;
  authState: TAuthState;
  navigateTo: string;
  setNavigateTo: (d: string) => void;
  setEmail: (d: string) => void;
  setPassword: (d: string) => void;
  setFieldType: (d: any) => void;
  setInEmail: (d: boolean) => void;
  setInPassword: (d: boolean) => void;
  handleLogin: (d: any) => void;
};

export const LoginPageHooks = ({ ...props }: TLoginPageHooksProps): TLoginPageHooksStates => {
  const { authState, login, loading } = useAuth();
  const { companyFetched } = useCompanyFetch();


  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [inEmail, setInEmail] = useState<boolean>(false);
  const [inPassword, setInPassword] = useState<boolean>(false);

  const [fieldType, setFieldType] = useState<any>("password");
  const [navigateTo, setNavigateTo] = useState<string>("");

  async function handleLogin(e: any) {
    e.preventDefault();

    const fields = [email, password];
    for (let i = 0; i < fields.length; i++) {
      let invalidField = false;

      if (!fields[0]) {
        setInEmail(true);
        invalidField = true
      };

      if (!fields[1]) {
        setInPassword(true);
        invalidField = true
      }

      if (invalidField) return toast.warning("Verifique os campos e tente novamente");
    }

    await login(email, password);

    window.location.pathname = RoutesPath.home;
    setNavigateTo(window.location.pathname);
  };

  return {
    ...props,
    email,
    password,
    fieldType,
    inEmail,
    inPassword,
    companyFetched,
    authState,
    navigateTo,
    setNavigateTo,
    setEmail,
    setPassword,
    setFieldType,
    setInEmail,
    setInPassword,
    handleLogin,
  }
}