import { NoData } from "../../../components/_index.components";
import { MyAccProfilePrivacyHooks, TMyAccProfilePrivacyHooksStates } from "./Hooks/MyAccProfilePrivacyHooks";

export type TMyAccProfilePrivacyProps = {};

export default function MyAccProfilePrivacy({ ...props }: TMyAccProfilePrivacyProps) {

  const states: TMyAccProfilePrivacyHooksStates = MyAccProfilePrivacyHooks({ ...props } as TMyAccProfilePrivacyHooksStates);

  return (
    <>
      <NoData />
    </>
  )
};