import { Navigate } from "react-router-dom";

import StructurePages from "../StructurePages";

import {
  MyAccountPageHooks,
  TMyAccountPageHooksStates
} from "../_index.pages";

import React from "react";

export type TMyAccountPageProps = {};

export default function MyAccountPage({ ...props }) {

  const states: TMyAccountPageHooksStates = MyAccountPageHooks({ ...props } as TMyAccountPageProps);

  return (
    <>
      {
        states.navigateTo &&
        <Navigate to={states.navigateTo} replace={true} />
      }
      <StructurePages
        module={
          <>
            <div className="w-full h-screen flex justify-center items-center flex-col py-10">
              <div className="w-full h-full inline-flex gap-8 nd:gap-0 nd:flex nd:flex-col">
                <aside className="w-auto h-auto flex flex-col min-w-[20%] nd:flex-wrap nd:flex-row nd:gap-2 md:min-w-[25%]">
                  <div className="w-full flex justify-center items-center mt-6 md:hidden lg:hidden xl:hidden 2xl:hidden">
                    <button
                      onClick={states.handleLogout}
                    >
                      <p className="whitespace-nowrap text-[#ec5555] text-base">Sair</p>
                    </button>
                  </div>
                  {
                    states.profileMenu?.map((item, i) => (
                      <button
                        key={i}
                        onMouseEnter={() => states.setMouseEvent && states.setMouseEvent(item._id)}
                        onMouseLeave={() => states.setMouseEvent && states.setMouseEvent(states.profileMenuFeatured)}
                        onClick={() => states.setProfileMenuFeatured && states.setProfileMenuFeatured(item._id)}
                        className="w-full h-auto rounded-md shadow-md inline-flex gap-6 p-5 items-center hover:filter hover:brightness-200 nd:w-[48%] nd:gap-2"
                        style={{
                          background: (
                            (
                              (
                                (states.mouseEvent && item._id === states.mouseEvent) ||
                                (states.profileMenuFeatured && item._id === states.profileMenuFeatured)
                              )
                            ) ? states.companyFetched?.primaryColorDark
                              : "none"
                          ),
                          color: (
                            (
                              (
                                (states.mouseEvent && item._id === states.mouseEvent) ||
                                (states.profileMenuFeatured && item._id === states.profileMenuFeatured)
                              )
                            ) ? "#fff"
                              : "#000"
                          )
                        }}
                      >
                        <item.icon />
                        <p className="whitespace-nowrap">{item.name}</p>
                      </button>
                    ))
                  }
                  <div className="w-full flex justify-center items-center mt-6 nd:hidden">
                    <button
                      className="w-full h-auto px-2 py-1 border border-[#ec5555] rounded-md bg-[#ec5555]"
                      onClick={states.handleLogout}
                    >
                      <p className="whitespace-nowrap text-white text-base">Sair</p>
                    </button>
                  </div>
                </aside>
                <section className="w-full h-auto">
                  {
                    (() => {
                      const item = states.profileMenu.find(item => item._id === states.profileMenuFeatured);
                      return item?.module ? React.createElement(item?.module) : null;
                    })()
                  }
                </section>
              </div>
            </div>
          </>
        }
      />
    </>
  )
};