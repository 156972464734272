import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";

import CompanyLogo from "../../assets/img/logo.png";

import { Icons } from "../../assets/icons/_index.icons";
import { useCompanyFetch } from "../../layout/_index.layout";
import { TStructurePages } from "../../pages/StructurePages";
import { RoutesPath } from "../../routes/AppRoutes.routes";
import { _ID } from "../../utils/_index.utils";
import { useAuth } from "../../pages/_index.pages";

import {
  Spinner,
  Image
} from "../_index.components";

type THeader = TStructurePages & {};

export default function Header({ ...props }: THeader) {
  const { companyFetched, loading } = useCompanyFetch();
  const { isAuthenticated, authState } = useAuth();

  const [navigateTo, setNavigateTo] = useState<string | null>("");

  const modules = [
    {
      name: "Atendimento",
      _id: "header-atendimento",
      onClick: null,
      icon: Icons.services,
    },
    {
      name: "Minha Conta",
      _id: _ID.header_my_account,
      onClick: isAuthenticated ? RoutesPath.login : RoutesPath.account,
      icon: Icons.profile,
      image: authState.user?.image,
    },
    {
      name: "Carrinho",
      _id: "header-carrinho",
      onClick: null,
      icon: Icons.shoppingCart,
    },
  ];

  const ref = useRef(null);

  useEffect(() => {

    function handleYAxel(event: MouseEvent) {

    }

    document.addEventListener("pointerdown", handleYAxel);

    return () => {
      document.removeEventListener("pointerdown", handleYAxel);
    };
  }, [ref]);

  useEffect(() => {
    if (!navigateTo)
      return;

    function handleNavigateTo() {
      window.location.pathname = navigateTo;
      return <Navigate to={window.location.pathname} replace={true} />
    }

    handleNavigateTo();
  }, [navigateTo])


  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );



  return (
    <>
      <section
        className="w-full h-auto min-h-[14vh] bg-bottom flex relative nd:min-h-[7vh]"
        style={{ backgroundPositionY: "67%" }}
      >
        <header
          ref={ref}
          className="w-full h-[14vh] nd:h-auto nd:min-h-[7vh] nd:max-h-[14vh] md:h-auto nd:p-0 md:p-2 flex justify-center items-center pr-80 xl:pr-60 nd:pr-0 md:px-5 xl:px-40 nd:px-0 fixed z-50"
          style={{
            background: companyFetched?.primaryColorDark,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5.5px)",
          }}
        >
          <div className="inline-flex w-full gap-6 justify-between items-center nd:px-5">
            <div className={`w-full h-auto flex items-center ${!props.hiddeHeader ? "justify-end" : "px-20 justify-start"}`}>
              <img
                // className="bg-cover w-[12.6vw] nd:w-auto cursor-pointer" // Okearo
                className="bg-cover w-auto max-w-[7vw] nd:w-auto cursor-pointer" // Delidebru
                onClick={() => setNavigateTo(RoutesPath.home)}
                src={CompanyLogo}
                alt="imgLogo"
              />
            </div>
            {
              !props.hiddeHeader &&
              <>
                <div className="w-full flex justify-start items-center nd:hidden">
                  <input
                    style={{
                      background: "rgba(156, 151, 151, 0.2)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      backdropFilter: "blur(5.5px)",
                    }}
                    placeholder="Buscar produtos, marcas e muito mais..."
                    className="w-full h-[5vh] rounded-lg px-4"
                  />
                </div>
                <div className="w-full h-full flex items-center justify-center gap-2 2xl:gap-8">
                  {
                    modules.map((item, i) => (
                      <button
                        key={i}
                        className="bg-none text-white text-lg nd:text-xs gap-2 inline-flex justify-center items-center whitespace-nowrap"
                        style={{
                          // color: brightnessIdentify()
                        }}
                        onClick={() => setNavigateTo(item.onClick)}
                      >

                        {
                          item.image
                            ? <div className="rounded-full ring-1 ring-gray-300 h-[1em] w-[1em] flex">
                              <Image
                                path={process.env.REACT_APP_BUCKET_URL + item.image.imageThumbUrl}
                                style="bg-cover w-full h-full rounded-md"
                              />
                            </div>
                            : <item.icon />
                        }
                        <div className="flex flex-col">
                          <p>
                            {
                              item._id === _ID.header_my_account
                                ? JSON.parse(localStorage.getItem('authState'))?.user?.name ?? item.name
                                : item.name
                            }
                          </p>
                        </div>
                      </button>
                    ))
                  }
                </div>
              </>
            }
          </div>
        </header>
      </section >
    </>
  )
}