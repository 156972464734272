import { RiCustomerService2Line } from "react-icons/ri";
import { TfiEmail } from "react-icons/tfi";
import { HiOutlineTrash } from "react-icons/hi";
import { BiPlus } from "react-icons/bi";

import {
  FaStreetView,
  FaUserCircle
} from "react-icons/fa";

import {
  GiShoppingCart,
  GiPadlock
} from "react-icons/gi";

import {
  BsInstagram,
  BsWhatsapp,
  BsFacebook,
  BsPersonVcard
} from "react-icons/bs";

import {
  GrFormNext,
  GrFormPrevious,
  GrAmex
} from "react-icons/gr";

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineCloudUpload,
} from "react-icons/ai";

import {
  MdClose,
  MdFavorite,
  MdFavoriteBorder,
  MdOutlineEdit,
  MdOutlinePrivacyTip,
  MdOutlineStar,
  MdOutlineStarBorder,
  MdOutlineStarHalf,
} from "react-icons/md";

import {
  SiDinersclub,
  SiDiscover,
  SiMastercard,
  SiVisa
} from "react-icons/si";

import {
  CiCreditCard2,
} from "react-icons/ci";


export const Icons = {
  shoppingCart: GiShoppingCart,
  profile: FaUserCircle,
  services: RiCustomerService2Line,
  next: GrFormNext,
  previous: GrFormPrevious,
  instagram: BsInstagram,
  whatsapp: BsWhatsapp,
  facebook: BsFacebook,
  eyeInvisible: AiFillEyeInvisible,
  eyeVisible: AiFillEye,
  email: TfiEmail,
  person: BsPersonVcard,
  padlock: GiPadlock,
  close: MdClose,
  edit: MdOutlineEdit,
  street: FaStreetView,
  card: CiCreditCard2,
  privacy: MdOutlinePrivacyTip,
  upload: AiOutlineCloudUpload,
  register: BiPlus,
  delete: HiOutlineTrash,
  mastercard: SiMastercard,
  visa: SiVisa,
  amex: GrAmex,
  diners: SiDinersclub,
  discover: SiDiscover,
  favorite: MdFavorite,
  willFavorite: MdFavoriteBorder,
  fullStar: MdOutlineStar,
  emptyStar: MdOutlineStarBorder,
  halfStar: MdOutlineStarHalf,


};