import { StructurePages } from "../_index.pages";

export type TTermsOfUsePageProps = {};

export default function TermsOfUsePage({ ...props }: TTermsOfUsePageProps) {
  window.scrollTo(0, 0);


  return (
    <>
      <StructurePages
        module={
          <>
            <section className="w-full h-full bg-none flex justify-center items-center text-gray-400 flex-col gap-16 nd:gap-16 mt-8 nd:mt-0 md:mt-0 lg:mt-16 py-20">
              <h1 className="text-5xl nd:text-3xl">Termos e Condições</h1>
              <div className="w-1/2 text-lg nd:w-full nd:px-5">
                <p>
                  Atualizado: 12 de março de 2024.
                </p>
                <br />
                <p>
                  A SeedzTech, Inc. ou suas empresas afiliadas e subsidiárias operam este site ("Site").
                  Este Site pode fornecer acesso a uma variedade de páginas da web, documentos, software,
                  serviços, imagens, gráficos, áudio, vídeo, fóruns, grupos de discussão, blogs e outros
                  conteúdos ("Conteúdo").
                </p>
                <br />
                <p className="ml-5">
                  1.	Você não usará o Site de maneira que viole quaisquer leis ou estes termos, infrinja os direitos de
                  terceiros (incluindo, mas não se limitando a, direitos de propriedade intelectual), seja ofensiva ou
                  interfira com o Site ou seus recursos. Por exemplo, você não usará o Site ou fornecerá Conteúdo ao Site que:
                </p>
                <br />
                <p className="ml-10">
                  a) infrinja, se aproprie indevidamente ou viole quaisquer direitos de propriedade intelectual de terceiros ou da SeedzTech;
                </p>
                <br />
                <p className="ml-10">
                  b) seja difamatório, calunioso, ameaçador, assediante, prejudicial a menores ou pornográfico;
                </p>
                <br />
                <p className="ml-10">
                  c) contenha conteúdo odioso, abusivo, obsceno ou violento;
                </p>
                <br />
                <p className="ml-10">
                  d) tente obter acesso não autorizado ao Site, ao servidor onde o Site está armazenado ou a
                  qualquer servidor, computador ou banco de dados conectado ao Site;
                </p>
                <br />
                <p className="ml-10">
                  e) gere ou facilite o envio de e-mails não solicitados ou implante qualquer robô, spider ou
                  aplicativo de recuperação de site/busca;
                </p>
                <br />
                <p className="ml-10">
                  f) contenha ou distribua vírus ou rotinas de programação destinadas a danificar, interceptar ou expropriar
                  sub-repticiamente qualquer sistema, dados ou informações;
                </p>
                <br />
                <p className="ml-10">
                  g) tente ou faça mineração ou falsificação de criptomoedas, permita compartilhamento ilegal de arquivos; ou
                </p>
                <br />
                <p className="ml-10">
                  h) restrinja ou iniba qualquer outro usuário de usar o Site através de "hacking", "cracking",
                  "spoofing" ou desfiguração de qualquer parte do Site.
                </p>
                <br />
                <p>
                  Você não pode postar materiais promocionais relacionados a sites ou serviços online que competem com a
                  SeedzTech ou o Site, ou postar publicidade ou solicitações comerciais, a menos que explicitamente acordado com a SeedzTech.
                </p>
                <br />
                <p>
                  Se a SeedzTech determinar que você agiu ilegalmente, em violação destes termos, ou de forma inadequada, a
                  SeedzTech pode encerrar sua Conta, remover Seu Conteúdo, proibi-lo de usar o Site, tomar medidas legais
                  apropriadas ou tomar outras ações que a SeedzTech considerar adequadas.
                </p>
                <br />
                <p>
                  O Site ou partes do Site também podem estar sujeitos a outras diretrizes, como um Código de Conduta.
                  A não conformidade com essas diretrizes pode resultar na indisponibilidade do Site ou da funcionalidade
                  relevante do Site para você ou outras consequências conforme descrito em tais diretrizes.
                </p>
                <br />
                <p>
                  A SeedzTech tem o direito, mas não a obrigação, de monitorar o uso do Site, qualquer Conteúdo e qualquer atividade no Site.
                </p>
                <br />
                <p className="ml-5">
                  2.	Feedback: Você pode fornecer ideias, sugestões, propostas ou outros feedbacks para a SeedzTech via Site
                  ("Contato"). A SeedzTech pode usar esse Feedback para qualquer finalidade, incluindo incorporá-lo ou usá-lo
                  para desenvolver e melhorar os produtos e outras ofertas da SeedzTech sem atribuição ou compensação para você.
                  Você concede à SeedzTech uma licença perpétua e irrevogável para usar todo o Feedback para qualquer finalidade.
                  Você concorda em fornecer Feedback para a SeedzTech apenas em conformidade com as leis aplicáveis.
                  Você declara que tem autoridade para fornecer o Feedback e que o Feedback não incluirá informações confidenciais.
                </p>
                <br />
                <p className="ml-5">
                  3.	Links e Referências a Serviços: O Site pode fornecer links para sites, produtos ou serviços de
                  terceiros, que você pode acessar ou usar por sua conta e risco, sujeito aos termos aplicáveis de terceiros.
                  A SeedzTech não é responsável e não endossou tais sites, produtos ou serviços de terceiros.
                </p>
                <br />
                <p>
                  O Site pode referir-se a produtos ou serviços da SeedzTech que podem não estar disponíveis em sua localização.
                  Entre em contato com seu representante local da SeedzTech para mais informações.
                </p>
                <br />
                <p className="ml-5">
                  4.	Isenção de Garantias EXCETO SE EXPRESSAMENTE ACORDADO DE OUTRA FORMA EM UM ACORDO ASSINADO ENTRE VOCÊ E A SEEDZTECH:
                </p>
                <br />
                <p>
                  O SITE, QUALQUER CONTEÚDO NO SITE E QUALQUER SOFTWARE OU SERVIÇOS FORNECIDOS NO SITE SÃO FORNECIDOS "COMO ESTÃO" E
                  A SEEDZTECH NÃO FORNECE GARANTIAS EXPRESSAS, IMPLÍCITAS OU ESTATUTÁRIAS, INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS
                  IMPLÍCITAS DE COMERCIABILIDADE, QUALIDADE SATISFATÓRIA, NÃO VIOLAÇÃO OU ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO.
                  A SEEDZTECH NÃO GARANTE QUE O SITE SERÁ SEGURO OU QUE O ACESSO E USO DO SITE SERÃO LIVRES DE ERROS, INTERRUPÇÕES,
                  TRAVAMENTOS OU TEMPOS DE INATIVIDADE.
                </p>
                <br />
                <p>
                  A SeedzTech pode alterar ou descontinuar parte ou todo o Site ou qualquer Conteúdo a qualquer momento.
                </p>
                <br />
                <p className="ml-5">
                  5.	Limitação de Responsabilidade e Indenização EXCETO SE EXPRESSAMENTE PREVISTO DE OUTRA FORMA
                  EM UM ACORDO ASSINADO ENTRE VOCÊ E A SEEDZTECH:
                </p>
                <br />
                <p>
                  EM NENHUM CASO A SEEDZTECH SERÁ RESPONSÁVEL POR QUAISQUER DANOS DIRETOS, INDIRETOS, ESPECIAIS, INCIDENTAIS
                  OU CONSEQUENTES, SEJA TAIS DANOS ALEGADOS COMO RESULTADO DE CONDUTA ILÍCITA OU VIOLAÇÃO DE CONTRATO OU
                  OUTRA FORMA, MESMO QUE A SEEDZTECH TENHA SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS SOB OU EM CONEXÃO
                  COM ESTES TERMOS, INCLUINDO, MAS NÃO SE LIMITANDO AO SITE, SEU ACESSO OU USO (OU SUA FALHA EM OBTER ACESSO OU USO)
                  DO SITE, QUALQUER CONTEÚDO FORNECIDO NO SITE OU QUALQUER SERVIÇO FORNECIDO NO SITE.
                  ESTA DECLARAÇÃO NÃO AFETA QUALQUER RESPONSABILIDADE QUE NÃO POSSA SER EXCLUÍDA OU LIMITADA SOB A LEI APLICÁVEL.
                </p>
                <br />
                <p>
                  Você defenderá e indenizará a SeedzTech de e contra quaisquer e todas as perdas, custos, danos e despesas
                  (incluindo honorários advocatícios razoáveis) incorridos como resultado de qualquer reivindicação, processo
                  ou ação judicial movida contra a SeedzTech em relação ao Seu Conteúdo ou como resultado de qualquer uso ou
                  uso indevido do Site por você.
                </p>
                <br />
                <p className="ml-5">
                  6.	Marcas Registradas As marcas registradas, logotipos, apresentações comerciais e marcas de
                  serviço exibidas no Site são propriedade da SeedzTech ou de terceiros. Seu uso de qualquer marca
                  registrada da SeedzTech deve estar de acordo com as Diretrizes e Políticas de Marcas Registradas
                  aplicáveis da SeedzTech ou outras diretrizes de marcas registradas aplicáveis.
                </p>
                <br />
                <p className="ml-5">
                  7.	Controle de Exportação Ao acessar qualquer software ou tecnologia disponível através deste Site,
                  você declara e garante que não está localizado em, sob o controle de ou é nacional ou residente de
                  qualquer país para o qual os Estados Unidos tenham embargado exportações de bens, serviços ou tecnologia.
                  Você reconhece que entende que o software e a tecnologia podem estar sujeitos aos Regulamentos de Administração
                  de Exportações dos EUA (o "EAR") e outras leis dos EUA e estrangeiras e não podem ser exportados,
                  reexportados ou transferidos (a) para qualquer destino proibido, (b) para qualquer usuário final que
                  tenha sido proibido de participar de transações de exportação dos EUA por qualquer agência federal do
                  governo dos EUA ou (c) para qualquer uso final proibido de acordo com a Parte 744 do EAR.
                </p>
                <br />
                <p className="ml-5">
                  8.	Renúncia, Separabilidade e Precedência A falha da SeedzTech em exercer ou fazer cumprir qualquer
                  direito ou disposição destes termos não constitui uma renúncia de tal direito ou disposição.
                  Se qualquer disposição destes termos for considerada inexequível, essa disposição será modificada
                  para ser exequível, ou se tal modificação não for possível, será removida e as disposições
                  restantes permanecerão em pleno vigor.
                </p>
                <br />
                <p>
                  Alguns serviços ou Conteúdos da SeedzTech oferecidos via o Site podem estar sujeitos a termos adicionais
                  ou separados. Se houver um conflito entre estes termos e tais termos adicionais ou separados, os
                  últimos terão precedência com relação ao seu acesso e uso daquele serviço ou Conteúdo da SeedzTech.
                  Nada nestes termos se destina a limitar seus direitos sob uma licença de código aberto.
                </p>
                <br />
                <p className="ml-5">
                  9.	Jurisdição. Estes termos e qualquer reivindicação, controvérsia ou disputa decorrente ou relacionada
                  a estes termos são regidos e interpretados de acordo com as leis do Estado de Nova York, sem dar
                  efeito a qualquer disposição sobre conflitos de leis.
                </p>
                <br />
                <p>
                  Se uma ou mais das disposições contidas nestes termos forem consideradas inválidas, ilegais ou inexequíveis
                  em qualquer aspecto por qualquer tribunal de jurisdição competente, tal decisão não prejudicará a validade,
                  legalidade ou exequibilidade das demais disposições.
                </p>
                <br />
                <p className="ml-5">
                  10.	Aviso de Direitos Autorais e Processo de Retirada. A SeedzTech e suas afiliadas respeitam a
                  propriedade intelectual de terceiros. Se você acredita que o conteúdo no Site infringe seus direitos
                  autorais, forneça as seguintes informações ao agente de direitos autorais designado pela SeedzTech
                  listado abaixo:
                </p>
                <br />
                <p>
                  Uma descrição do conteúdo protegido por direitos autorais que você alega ter sido infringido e uma
                  descrição da atividade infratora, incluindo onde o conteúdo está localizado, como um URL.
                  Seu nome, endereço, número de telefone e endereço de e-mail.
                </p>
                <br />
                <p>
                  Se você acredita que o conteúdo no Site foi removido por engano, forneça uma contra-notificação
                  ao agente de direitos autorais designado pela SeedzTech listado abaixo:
                </p>
                <br />
                <p>
                  Uma descrição do material que a SeedzTech removeu ou ao qual a SeedzTech desativou o acesso.
                  Seu nome, endereço, número de telefone, endereço de e-mail e uma declaração de que você
                  consente com a jurisdição do Tribunal Distrital Federal para o distrito judicial onde seu
                  endereço está localizado. E que você aceitará a notificação do processo da pessoa que forneceu
                  a notificação de infração ou um agente de tal pessoa.  Por favor, envie notificações de
                  infração e contra-notificações para o agente de direitos autorais designado pela SeedzTech
                  para: comercial@seedztech.com.br
                </p>
              </div>
            </section>
          </>
        }
      />
    </>
  )
};